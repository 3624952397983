import React from "react";

const IconChevronDoubleRight = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M6.0405 18.174C6.09032 18.4236 6.21786 18.6763 6.44607 18.829C6.90148 19.1346 7.54326 19.0058 7.8472 18.5475L11.8333 12.5563C12.0556 12.2218 12.0556 11.7684 11.8333 11.4339L7.8472 5.44277C7.54326 4.98445 6.90148 4.85566 6.44607 5.16121C5.98965 5.46676 5.86112 6.10781 6.16506 6.56514L9.77749 11.9951L6.16506 17.4251C6.01359 17.6538 5.99067 17.9234 6.0405 18.174Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12.0405 18.1789C12.0903 18.4285 12.2179 18.6811 12.4461 18.8339C12.9015 19.1395 13.5433 19.0107 13.8472 18.5523L17.8333 12.5612C18.0556 12.2267 18.0556 11.7733 17.8333 11.4388L13.8472 5.44766C13.5433 4.98933 12.9015 4.86054 12.4461 5.16609C11.9897 5.47164 11.8611 6.1127 12.1651 6.57002L15.7775 12L12.1651 17.43C12.0136 17.6586 11.9907 17.9282 12.0405 18.1789Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChevronDoubleRight;
