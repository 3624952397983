import React from "react";

const IconPerson = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95792 3.28761C10.1166 2.75862 9.81643 2.20114 9.28744 2.04244C9.121 1.99251 9.00008 2 9.00008 2H14.0001C14.3215 2 14.6234 2.15452 14.8113 2.41529C14.9993 2.67606 15.0504 3.01128 14.9488 3.31623L13.3875 8H18.0001C18.3689 8 18.7077 8.20299 18.8818 8.52814C19.0558 8.8533 19.0367 9.24785 18.8321 9.5547L10.8475 21.5316C10.9228 21.4118 10.9737 21.274 10.9924 21.1243L11.5178 16.9206L16.1316 10H12.0001C11.6786 10 11.3768 9.84548 11.1888 9.58471C11.0009 9.32394 10.9498 8.98872 11.0514 8.68377L12.6127 4H9.7442L9.95792 3.28761Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28736 2.04219C9.81635 2.20089 10.1165 2.75837 9.95783 3.28737L7.34404 12H11C11.2868 12 11.5599 12.1232 11.7497 12.3382C11.9395 12.5532 12.0279 12.8394 11.9923 13.1241L10.9923 21.1241C10.9238 21.6721 10.424 22.0608 9.87597 21.9923C9.32795 21.9238 8.93923 21.424 9.00773 20.876L9.86723 14H6.00001C5.6839 14 5.38641 13.8506 5.19772 13.5969C5.00902 13.3433 4.95135 13.0154 5.04218 12.7127L8.04218 2.71267C8.20088 2.18368 8.75836 1.8835 9.28736 2.04219Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPerson;
