import React from "react";

const IconLink = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M7.58456 21.9986C8.87616 21.9655 10.1226 21.3681 11.2147 20.2736L12.748 18.7374C13.1391 18.3458 13.1391 17.7182 12.748 17.3267C12.3569 16.9351 11.699 16.9351 11.3079 17.3267L9.80676 18.8629C8.35371 20.3188 6.74222 20.4644 5.14476 18.8629C3.5463 17.2624 3.69171 15.6478 5.14476 14.1919L8.17922 11.1505C9.63228 9.69462 11.2438 9.55004 12.8412 11.1505L13.2794 11.5893C14.2231 12.5352 15.632 11.1244 14.6874 10.1786L14.2491 9.73981C11.8063 7.29186 8.95538 7.55191 6.77129 9.73981L3.73683 12.7812C1.55274 14.9691 1.29302 17.8257 3.73683 20.2736C4.95823 21.4976 6.29396 22.0308 7.58456 21.9986Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M13.7749 15.9905C14.981 15.9122 16.1551 15.3342 17.2216 14.2674L20.2606 11.2276C22.708 8.78087 22.4479 5.9257 20.2606 3.73892C18.0742 1.55214 15.2181 1.29221 12.7716 3.73892L11.236 5.27438C10.8444 5.66578 10.8444 6.29301 11.236 6.6844C11.6277 7.07579 12.2865 7.07579 12.6782 6.6844L14.1816 5.14894C15.7825 3.54824 17.3953 3.69376 18.8506 5.14894C20.3058 6.60412 20.4514 8.21686 18.8506 9.81756L15.8116 12.8574C15.0734 13.595 14.3443 13.9402 13.6493 13.9854C12.8088 14.0406 11.927 13.6422 11.1426 12.8574L10.7038 12.4188C10.3121 12.0274 9.68543 12.0274 9.29376 12.4188C8.90208 12.8102 8.90208 13.4374 9.29376 13.8288L9.73263 14.2674C10.8946 15.4295 12.2946 16.0879 13.7749 15.9905Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconLink;
