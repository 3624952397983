import React from "react";

const IconGem = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.22937 10.6373L11.226 21.6333C11.416 21.8654 11.7001 22 12 22C12.2999 22 12.584 21.8654 12.7739 21.6333L21.774 10.6333C21.774 10.6333 21.5 11 21 11H18.8898L14.8452 15.9434L16.5899 11H14.469L12 17.9954L9.53105 11H7.41014L9.15492 15.9435L5.1102 11H2.99996C2.6996 11 2.41779 10.8652 2.22937 10.6373Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8944 3.55278L21.8944 9.55278C22.0494 9.86277 22.0329 10.2309 21.8507 10.5257C21.6684 10.8205 21.3466 11 21 11C14.9912 11 9.01203 11 3 11C2.65342 11 2.33156 10.8205 2.14935 10.5257C1.96714 10.2309 1.95058 9.86277 2.10557 9.55278L5.10557 3.55278C5.27145 3.22103 5.64668 2.99167 6.02204 2.99999H17.9779C18.3591 2.99154 18.7234 3.21064 18.8944 3.55278ZM16.5865 4.99999H7.41342L8.82518 8.99999H15.1747L16.5865 4.99999ZM17.2957 8.99999H19.382L18.159 6.55397L17.2957 8.99999ZM4.61804 8.99999L5.841 6.55406L6.70427 8.99999H4.61804Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconGem;
