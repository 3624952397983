import React from "react";

const IconGear = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M8.96802 2.45547C7.55002 2.90547 6.32702 3.63947 5.24902 4.64347C4.91602 4.95347 4.81403 5.46147 5.03003 5.86147C5.83103 7.34247 4.99302 8.92747 3.18602 9.01847C2.74402 9.04047 2.35302 9.36848 2.24902 9.79948C2.07002 10.5445 1.99902 11.1685 1.99902 11.9865C1.99902 12.6735 2.07302 13.4515 2.21802 14.1435C2.30802 14.5755 2.68402 14.8865 3.12402 14.9245C4.94202 15.0815 5.84203 16.4685 5.03003 18.2365C4.85003 18.6295 4.93202 19.0995 5.24902 19.3935C6.31102 20.3755 7.53102 21.0685 8.96802 21.5185C9.37802 21.6465 9.84102 21.4915 10.093 21.1435C11.205 19.6045 12.818 19.5995 13.874 21.1435C14.123 21.5065 14.579 21.6815 14.999 21.5495C16.386 21.1125 17.678 20.3695 18.749 19.3935C19.079 19.0925 19.166 18.6055 18.968 18.2055C18.136 16.5265 19.093 14.9855 20.811 14.9555C21.267 14.9475 21.673 14.6485 21.78 14.2055C21.953 13.4885 21.999 12.8645 21.999 11.9865C21.999 11.2325 21.91 10.4895 21.749 9.76847C21.647 9.31147 21.248 8.98747 20.78 8.98647C19.089 8.98347 18.141 7.32147 18.968 5.86147C19.198 5.45547 19.126 4.95747 18.78 4.64347C17.69 3.65347 16.361 2.87947 14.968 2.45547C14.54 2.32547 14.085 2.48547 13.843 2.86147C12.877 4.36247 11.073 4.38847 10.124 2.89347C9.88102 2.50947 9.40002 2.31847 8.96802 2.45547ZM15.046 4.57947C15.729 4.86547 16.268 5.16448 16.887 5.63348C16.161 7.93048 17.392 10.3165 19.942 10.8975C20.005 11.3105 19.999 11.5605 19.999 11.9865C19.999 12.4955 20.006 12.6745 19.948 13.0425C17.409 13.5685 16.153 15.8875 16.86 18.3385C16.252 18.7795 15.817 19.0855 15.054 19.3805C13.262 17.5565 10.774 17.4765 8.94403 19.3925C8.23003 19.0785 7.68104 18.7995 7.12604 18.3305C7.81404 15.8415 6.65103 13.6925 4.06903 13.0405C3.95403 12.5845 4.00002 11.2965 4.06602 10.9055C6.73602 10.2655 7.78803 7.90247 7.12503 5.62647C7.71103 5.18547 8.23803 4.86448 8.92303 4.58648C10.648 6.34148 13.238 6.51648 15.046 4.57947Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
        fill={fill}
        fillOpacity={0.4}
      />
    </svg>
  );
};

export default IconGear;
