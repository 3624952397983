import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Tabs, Form, Button, Checkbox, Alert } from "antd";
import { UserTypeContext } from "../contexts/UserTypeContext";
import {
  authLogin,
  authLoginGirl,
  authRegister,
  authRegisterGirl,
  getUserInfo,
  getUserInfoGirl,
} from "../utils/Api";
import { InputUi, SelectUi } from "./ui";
import useMessage from "../hooks/useMessage";
import { IconBriefcase, IconKey, IconKeyFill, IconMail } from "../utils/Icons";

const Auth = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const { setEmail, setToken, setGirl, setUserInfo, setGirlList } =
    useContext(UserTypeContext);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [auth, setAuth] = useState({
    email: "",
    type: "",
    password: "",
    confirmPassword: "",
    remember: false,
  });
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const [alert, setAlert] = useState(null);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setAuth((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleLogin = useCallback(async () => {
    const { email, password, remember, type } = auth;
    if (!email || !password || !type) {
      showErrorMessage(t("auth.errors.missingFields"));
      return;
    }

    const authFunction = type === "girl" ? authLoginGirl : authLogin;
    const getUserInfoFunction = type === "girl" ? getUserInfoGirl : getUserInfo;

    try {
      const response = await authFunction(email, password);
      if (response.error) {
        showErrorMessage(t("auth.errors.userNotFound"));
        return;
      }

      setToken(response.token);
      setEmail(email);

      const userInfo = await getUserInfoFunction(email, response.token);
      setUserInfo(userInfo);

      if (type === "girl") {
        setGirl();
      }

      if (remember) {
        localStorage.setItem("email", email);
        localStorage.setItem("pas", password);
        localStorage.setItem("checkMe", remember);
        localStorage.setItem("type", type);
      }
      navigate("/user");
    } catch (error) {
      showErrorMessage(t("auth.errors.serverError"));
    }
  }, [
    auth,
    showErrorMessage,
    t,
    setToken,
    setEmail,
    setUserInfo,
    navigate,
    setGirl,
  ]);

  const handleSignUp = useCallback(async () => {
    const { email, password, confirmPassword, type } = auth;

    if (!email || !password) {
      showErrorMessage(t("auth.errors.missingFields"));
      return;
    }

    if (password !== confirmPassword) {
      showErrorMessage(t("auth.errors.passwordMismatch"));
      return;
    }

    const authFunction = type === "girl" ? authRegisterGirl : authRegister;

    try {
      const response = await authFunction(email, password);

      if (response.error === "Failed! Email is already in use!") {
        showErrorMessage(t("auth.errors.emailInUse"));
        return;
      }

      showSuccessMessage(t("auth.success.registrationSuccess"));
      clearForm();
    } catch (error) {
      showErrorMessage(t("auth.errors.serverError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, t, showErrorMessage, showSuccessMessage]);

  const clearForm = useCallback(() => {
    setAuth({
      email: "",
      password: "",
      confirmPassword: "",
      type: "",
    });
  }, []);

  useEffect(() => {
    const checkRememberedLogin = async () => {
      const email = localStorage.getItem("email");
      const password = localStorage.getItem("pas");
      const remember = localStorage.getItem("checkMe");
      const type = localStorage.getItem("type");

      if (remember) {
        const authFunction = type === "girl" ? authLoginGirl : authLogin;
        const getUserInfoFunction = type === "girl" ? getUserInfoGirl : getUserInfo;
          if(type==="girl")setGirl()
        const response = await authFunction(email, password);
        if (response.error) {
          localStorage.clear();
        } else {
          setToken(response.token);
          setEmail(email);

          const userInfo = await getUserInfoFunction(email, response.token);
          setUserInfo(userInfo);
          navigate("/user");
        }
      }
    };

    if (show) checkRememberedLogin();
  }, [show]);

  const authTabs = [
    {
      key: "1",
      label: t("auth.loginTab"),
      children: (
        <Form form={form} layout="vertical">
          <InputUi
            name="email"
            type={"email"}
            value={auth.email}
            label={t("auth.form.email.label")}
            placeholder={t("auth.form.email.placeholder")}
            onChange={handleInputChange}
            icon={<IconMail width="22" height="22" fill="var(--color-main)" />}
          />
          <InputUi
            name="password"
            type="password"
            value={auth.password}
            label={t("auth.form.pass.label")}
            placeholder={t("auth.form.pass.placeholder")}
            onChange={handleInputChange}
            icon={<IconKey width="22" height="22" fill="var(--color-main)" />}
          />
          <SelectUi
            name="type"
            label={t("auth.form.type.label")}
            placeholder={t("auth.form.type.placeholder")}
            onChange={(value) =>
              setAuth((prevState) => ({ ...prevState, type: value }))
            }
            options={[
              { value: "token", label: t("auth.form.type.item.user") },
              { value: "girl", label: t("auth.form.type.item.girl") },
            ]}
            icon={
              <IconBriefcase width="22" height="22" fill="var(--color-main)" />
            }
          />
          <Form.Item>
            <Checkbox
              checked={auth.remember}
              onChange={(e) =>
                setAuth((prevState) => ({
                  ...prevState,
                  remember: e.target.checked,
                }))
              }
            >
              {t("auth.form.remember")}
            </Checkbox>
          </Form.Item>
          <div className="flex justify-center">
            <Button type="primary" onClick={handleLogin}>
              {t("auth.form.btnLog")}
            </Button>
          </div>
          {alert && (
            <Alert
              message={alert}
              type="error"
              showIcon
              closable
              onClose={() => setAlert(null)}
            />
          )}
        </Form>
      ),
    },
    {
      key: "2",
      label: t("auth.signUpTab"),
      children: (
        <Form form={form} layout="vertical">
          <InputUi
            name="email"
            type={"email"}
            value={auth.email}
            label={t("auth.form.email.label")}
            placeholder={t("auth.form.email.placeholder")}
            onChange={handleInputChange}
            icon={<IconMail width="22" height="22" fill="var(--color-main)" />}
          />
          <SelectUi
            name="type"
            label={t("auth.form.type.label")}
            placeholder={t("auth.form.type.placeholder")}
            onChange={(value) =>
              setAuth((prevState) => ({ ...prevState, type: value }))
            }
            options={[
              { value: "token", label: t("auth.form.type.item.user") },
              { value: "girl", label: t("auth.form.type.item.girl") },
            ]}
            icon={
              <IconBriefcase width="22" height="22" fill="var(--color-main)" />
            }
          />
          <InputUi
            name="password"
            type="password"
            value={auth.password}
            label={t("auth.form.pass.label")}
            placeholder={t("auth.form.pass.placeholder")}
            onChange={handleInputChange}
            icon={<IconKey width="22" height="22" fill="var(--color-main)" />}
          />
          <InputUi
            name="confirmPassword"
            type="password"
            value={auth.confirmPassword}
            label={t("auth.form.passConfirm.label")}
            placeholder={t("auth.form.passConfirm.placeholder")}
            onChange={handleInputChange}
            icon={
              <IconKeyFill width="22" height="22" fill="var(--color-main)" />
            }
          />
          <div className="flex justify-center">
            <Button type="primary" onClick={handleSignUp}>
              {t("auth.form.btnSig")}
            </Button>
          </div>
          {alert && (
            <Alert
              message={alert}
              type="error"
              showIcon
              closable
              onClose={() => setAlert(null)}
            />
          )}
        </Form>
      ),
    },
  ];

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      title={t("auth.title")}
      footer={null}
    >
      <Tabs defaultActiveKey="1" items={authTabs} type="card" />
    </Modal>
  );
};

export default Auth;
