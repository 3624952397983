import React, { useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Alert, Button, Col, Form, Row } from "antd";
import UserLayout from "../../components/UserLayout";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import {
  changePassword,
  changePasswordGirl,
  getUserInfo,
  getUserInfoGirl,
  updateUserInfo,
  updateUserInfoGirl,
} from "../../utils/Api";
import {
  DatePickerUi,
  InputNumberUi,
  InputUi,
  LocationSelectUi,
  WalletConnectUi,
} from "../../components/ui";
import UploadCoverModal from "../../components/Modal/UploadCoverModal";
import UploadAvatarModal from "../../components/Modal/UploadAvatarModal";
import {
  IconArrowClockwise,
  IconBadgeCheck,
  IconImage,
  IconInfoCircle,
  IconKey,
  IconKeyFill,
  IconMail,
  IconPerson,
  IconPersonBox,
  IconTelephone,
  IconUpload,
} from "../../utils/Icons";
import VerificationModal from "../../components/Modal/VerificationModal";

const Settings = () => {
  const { t } = useTranslation();
  const basePath = "user.settings.";

  const { isWalletConnect, user_info, token, email, setUserInfo, girl } =
    useContext(UserTypeContext);

  const [form] = Form.useForm();

  const [userInfo, setUserInfoState] = useState(user_info);

  const [isFieldDisabled, setIsFieldDisabled] = useState({
    name: !!user_info.name,
    phone: !!user_info.phone,
    date_bith: !!user_info.date_bith,
    location: !!(user_info.location?.country && user_info.location?.city),
    btnSave: !!(user_info.name && user_info.phone && user_info.date_bith),
  });

  const [isCoverModalOpen, setIsCoverModalOpen] = useState(false);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isVerifModalOpen, setIsVerifiModalOpen] = useState(false);

  const [passwords, setPasswords] = useState({
    oldPass: "",
    newPass: "",
    confirmNewPass: "",
  });

  const disabledDate = (current) => {
    return current && current > dayjs().subtract(18, "year");
  };

  const fetchUserInfo = useCallback(async () => {
    const fetchUser = girl ? getUserInfoGirl : getUserInfo;
    const res = await fetchUser(email, token);
    setUserInfo(res); 
    setUserInfoState(res)
  }, [girl, email, token, setUserInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfoState((prev) => ({ ...prev, [name]: value }));
    form.setFieldsValue({ [name]: value });
  };

  const handleDateChange = (date) => {
    if (date && dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("DD.MM.YYYY");
      setUserInfoState((prev) => ({ ...prev, date_bith: formattedDate }));
      form.setFieldsValue({ date_bith: formattedDate });
    } else {
      console.log("Invalid date selected");
    }
  };

  const handleLocationChange = (newLocation) => {
    setUserInfoState((prev) => ({ ...prev, location: newLocation }));
    form.setFieldsValue({ location: newLocation });
  };

  const handleSaveInfo = async () => {
    try {
      // await form.validateFields();

      const updatedUserInfo = { ...userInfo };
      if (
        userInfo.name &&
        userInfo.date_bith &&
        userInfo.phone &&
        userInfo.location?.country
      ) {
        updatedUserInfo.profile = true;
      }
      setUserInfoState(updatedUserInfo);

      const updateUser = girl ? updateUserInfoGirl : updateUserInfo;
      await updateUser(updatedUserInfo, token);

      setIsFieldDisabled({
        name: true,
        phone: true,
        date_bith: true,
        location: true,
        btnSave: true,
      });

      fetchUserInfo();
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  // const handleSaveInfoPhoto = async () => {
  //   const updateUser = girl ? updateUserInfoGirl : updateUserInfo;
  //   await updatePhoto(photo,'avatar', token);
  //   fetchUserInfo();
  // };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handlePasswordUpdate = async () => {
    const { oldPass, newPass, confirmNewPass } = passwords;
    if (oldPass && newPass === confirmNewPass && oldPass !== newPass) {
      const updatePassword = girl ? changePasswordGirl : changePassword;
      await updatePassword(email, oldPass, newPass, token);
      fetchUserInfo();
      setPasswords({ oldPass: "", newPass: "", confirmNewPass: "" });
    }
  };
  return (
    <UserLayout
      userInfo={user_info}
      setUserInfoState={setUserInfoState}
      className="page settings"
    >
      {!user_info.profile ||
      !user_info.photo_title ||
      !user_info.photo_cover ? (
        <Alert
          type="info"
          message={t(`${basePath}alert`)}
          className="large"
          showIcon
          icon={
            <IconInfoCircle width="22" height="22" fill="var(--color-blue)" />
          }
        />
      ) : null}
      {!isWalletConnect && !user_info.title ? (
        <Alert
          type="info"
          message={t(`${basePath}personal.alertWallet`)}
          className="large"
          showIcon
          icon={
            <IconInfoCircle width="22" height="22" fill="var(--color-blue)" />
          }
        />
      ) : null}
      {user_info.verif !==3 ? (
        <Alert
          type="info"
          message={t(`${basePath}personal.alertVerif`)}
          className="large"
          showIcon
          icon={
            <IconInfoCircle width="22" height="22" fill="var(--color-blue)" />
          }
        />
      ) : null}
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <div className="mob-wallet">
            <h4 className="title-block">{t(`${basePath}wallet`)}</h4>
            <div className="box">
              <WalletConnectUi />
            </div>
          </div>
          <h4 className="title-block">
            {t(`${basePath}personal.title`)}
            <div className="verif-btn">
              {user_info.verif === 3 ? (
                <div className="verified">
                  <IconBadgeCheck
                    width="20"
                    height="20"
                    fill="var(--color-green)"
                  />
                  {t(`${basePath}personal.verif.verified`)}
                </div>
              ) : user_info.verif === 2 ? (
                <div className="verifprogres">
                  <IconArrowClockwise width="16" height="16" fill={"#e5a23f"} />
                  {t(`${basePath}personal.verif.proccesing`)}
                </div>
              ) : (
                <div className="verif">
                  <Button onClick={() => setIsVerifiModalOpen(true)}>
                    <IconPersonBox
                      width="20"
                      height="20"
                      fill="var(--color-font)"
                    />
                    {t(`${basePath}personal.verif.btn`)}
                  </Button>
                </div>
              )}
            </div>
          </h4>
          <div className="box">
            <Form form={form} layout="vertical">
              <InputUi
                name="name"
                label={t(`${basePath}personal.form.name.label`)}
                value={userInfo.name}
                placeholder={t(`${basePath}personal.form.name.placeholder`)}
                onChange={handleInputChange}
                icon={
                  <IconPerson width="22" height="22" fill="var(--color-main)" />
                }
                errorMess={t(`${basePath}personal.form.name.error`)}
                disabled={isFieldDisabled.name}
              />
              <InputUi
                name="email"
                label={t(`${basePath}personal.form.email.label`)}
                value={email}
                icon={
                  <IconMail width="22" height="22" fill="var(--color-main)" />
                }
                disabled
                required={false}
              />
              <DatePickerUi
                name="date_bith"
                label={t(`${basePath}personal.form.birthday.label`)}
                format="DD.MM.YYYY"
                value={
                  userInfo.date_bith
                    ? dayjs(userInfo.date_bith, "DD.MM.YYYY")
                    : null
                }
                placeholder={userInfo.date_bith || "01.01.2024"}
                onChange={handleDateChange}
                errorMess={t(`${basePath}personal.form.birthday.error`)}
                disabled={isFieldDisabled.date_bith}
                disabledDate={disabledDate}
              />
              <InputNumberUi
                name="phone"
                label={t(`${basePath}personal.form.phone.label`)}
                value={userInfo.phone}
                placeholder={t(`${basePath}personal.form.phone.placeholder`)}
                onChange={handleInputChange}
                icon={
                  <IconTelephone
                    width="22"
                    height="22"
                    fill="var(--color-main)"
                  />
                }
                errorMess={t(`${basePath}personal.form.phone.error`)}
                disabled={isFieldDisabled.phone}
              />
              <LocationSelectUi
                user_info={userInfo}
                onLocationChange={handleLocationChange}
                isDisabled={isFieldDisabled.location}
              />
              <div className="flex justify-center">
                <Button
                  onClick={handleSaveInfo}
                  type="primary"
                  disabled={isFieldDisabled.btnSave}
                >
                  {t(`${basePath}personal.form.btn`)}
                </Button>
              </div>
            </Form>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <h4 className="title-block">{t(`${basePath}image.title`)}</h4>
          <div className="box avatar">
            <div className="item-photo">
              <span className="label">
                {t(`${basePath}image.avatar.title`)}
                <IconPersonBox
                  width="22"
                  height="22"
                  fill="var(--color-main)"
                />
              </span>
              <Button type="primary" onClick={() => setIsAvatarModalOpen(true)}>
                <IconUpload width="20" height="20" fill="var(--color-main)" />
                {t(`${basePath}image.avatar.btn`)}
              </Button>
            </div>
            <div className="item-photo">
              <span className="label">
                {t(`${basePath}image.cover.title`)}
                <IconImage width="22" height="22" fill="var(--color-main)" />
              </span>
              <Button type="primary" onClick={() => setIsCoverModalOpen(true)}>
                <IconUpload width="20" height="20" fill="var(--color-main)" />
                {t(`${basePath}image.cover.btn`)}
              </Button>
              {/* <Button onClick={() => setIsVerifiModalOpen(true)}>
                    <IconPersonBox
                      width="20"
                      height="20"
                      fill="var(--color-font)"
                    />
                    {t(`${basePath}personal.verif.btn`)}
                  </Button> */}
            </div>
          </div>
          <h4 className="title-block">{t(`${basePath}changePass.title`)}</h4>
          <div className="box">
            <InputUi
              name="oldPass"
              type="password"
              label={t(`${basePath}changePass.oldPass`)}
              value={passwords.oldPass}
              onChange={handlePasswordChange}
              icon={<IconKey width="22" height="22" fill="var(--color-main)" />}
            />
            <InputUi
              name="newPass"
              type="password"
              label={t(`${basePath}changePass.newPass`)}
              value={passwords.newPass}
              onChange={handlePasswordChange}
              icon={<IconKey width="22" height="22" fill="var(--color-main)" />}
            />
            <InputUi
              name="confirmNewPass"
              type="password"
              label={t(`${basePath}changePass.confirmPass`)}
              value={passwords.confirmNewPass}
              onChange={handlePasswordChange}
              icon={
                <IconKeyFill width="22" height="22" fill="var(--color-main)" />
              }
            />
            <div className="flex justify-center">
              <Button onClick={handlePasswordUpdate} type="primary">
                {t(`${basePath}changePass.btn`)}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <UploadAvatarModal
        girl={girl}
        fetchUserInfo={fetchUserInfo}
        token={token}
        isModalOpen={isAvatarModalOpen}
        handleCancel={() => setIsAvatarModalOpen(false)}
      />
      <UploadCoverModal
        girl={girl}
        fetchUserInfo={fetchUserInfo}
        token={token}
        isModalOpen={isCoverModalOpen}
        handleCancel={() => setIsCoverModalOpen(false)}
      />
      <VerificationModal
        isModalOpen={isVerifModalOpen}
        handleCancel={() => setIsVerifiModalOpen(false)}
        fetchUserInfo={fetchUserInfo}
      />
    </UserLayout>
  );
};

export default Settings;
