import React from "react";

const IconArrowClockwise = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 8.13401 8.13401 5 12 5C13.9445 5 15.7022 5.79153 16.9718 7.07242C17.3141 7.41778 17.8719 8.04993 18.3616 8.61765C18.4776 8.75214 18.5884 8.88134 18.6897 9C19 9 19.5 9 20 9V7.45534C19.9595 7.4082 19.9181 7.36011 19.876 7.31133C19.3919 6.75012 18.7907 6.0665 18.3923 5.6645C16.7626 4.02023 14.4995 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C15.3325 21 18.2407 19.1882 19.7953 16.5007C20.0719 16.0227 19.9085 15.4109 19.4305 15.1344C18.9524 14.8579 18.3407 15.0212 18.0641 15.4993C16.8523 17.5942 14.5897 19 12 19C8.13401 19 5 15.866 5 12Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3V10C22 10.5523 21.5523 11 21 11H14V9H20V3H22Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrowClockwise;
