import React, { useContext } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import UserLayout from "../../components/UserLayout";
import ChatUi from "../../components/ui/elements/ChatUi";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import { getUserInfo, getUserInfoGirl } from "../../utils/Api";

const Chats = () => {
  const { t } = useTranslation();
  const basePath = "user.chat.";

  const { user_info, token, setUserInfo, girl, email } =
    useContext(UserTypeContext);

  const getUser = async () => {
    if (girl) {
      const res = await getUserInfoGirl(email, token);
      setUserInfo(res);
    } else {
      const res = await getUserInfo(email, token);
      setUserInfo(res);
    }
  };

  return (
    <UserLayout userInfo={user_info} className={"page chat"}>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <h4 className="title-block">{t(`${basePath}title`)}</h4>
          <ChatUi girl={girl} getUser={getUser} userinfo={user_info} size={"large"} />
        </Col>
      </Row>
    </UserLayout>
  );
};

export default Chats;
