import React from "react";

const IconBuilding = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5014 2.125C16.6503 2.042 16.8313 2 17.0012 2H20.0003C20.5521 2 21 2.448 21 3V20C21 20.552 20.5521 21 20.0003 21H16.0325C15.4807 21 15.0328 20.552 15.0328 20C15.0328 19.448 15.4807 19 16.0325 19H19.0006V4H17.2822L9.0037 8.59399V19H11.9408C12.4926 19 12.9405 19.448 12.9405 20C12.9405 20.552 12.4926 21 11.9408 21H8.00401C7.94653 21 7.89017 20.9951 7.83533 20.9858H2.99969C2.44758 20.9858 2 20.5381 2 19.9858V11.9858C2 11.607 2.21394 11.2608 2.55262 11.0914L7.00432 8.86484V8C7.00432 7.637 7.18726 7.301 7.50416 7.125L16.5014 2.125ZM7.00432 11.1009L3.99938 12.6038V18.9858H7.00432V11.1009Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconBuilding;
