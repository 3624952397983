export const TRANSLATIONS_RU = {
    navBar: {
        lang: {
            'en-US': "English",
            'de-DE': "German",
            'es-ES': "Spanish",
            'ru-RU': "Русский"
        },
        profile: "Профиль",
        exit: 'Выйти',
        logIn: "Войти",
        menu: {
            profil: "Профиль",
            chats: "Чаты",
            setting: "Настройки",
            exit: 'Выход',
        }
    },

    sideBar: {
        menu: {
            profil: "Профиль",
            chats: "Чаты",
            friend: "Друзья",
            media: "Медиа",
            setting: "Настройки",
            girls: "Девочки",
            exit: 'Выход',
        },
    },

    auth: {
        title: "Авторизация",
        loginTab: 'Вход',
        signUpTab: 'Регестрация',
        form: {
            email: {
                label: "Вход",
                placeholder: "Введите ваш логин",
            },
            pass: {
                label: "Пароль",
                placeholder: "Введите ваш пароль",
            },
            passConfirm: {
                label: "Подтвердите ваш пароль",
                placeholder: "Введите ваш пароль",
            },
            type: {
                label: "Тип аккаунта",
                placeholder: "Выберите тип аккаунта",
                item: {
                    user: 'Пользыватель',
                    girl: 'Участник',
                }
            },
            remember: "Запомни меня",
            btnLog: 'Войти',
            btnSig: 'Регистрация'
        },
        success: {
            registrationSuccess: "You have successfully registered! Please log in to your account.",
        },
        errors: {
            userNotFound: "Пользователь не найден!",
            serverError: "Ошибка сервера",
            missingFields: "Пожалуйста, заполните все поля!",
            passwordMismatch: "Пароли не совпадают",
            emailInUse: "Не удалось! Имя пользывателя уже используется!",
        },
    },

    home: {
        banner: {
            title: "Познакомьтесь с очаровательными девушками и начните знакомиться прямо сейчас!",
            desc: "Создайте профиль и откройте для себя мир знакомств с интересными и активными девушками, которые ждут вашего сообщения.",
            btn: "Начать!",
        },

        cta: {
            item1: {
            title: "Знакомьтесь с девушками!",
            desc: "Создайте профиль и начните общаться с девушками, которые ждут вашего сообщения.",
            },
            item2: {
            title: "Найдите новых знакомых!",
            desc: "Создайте профиль и начните получать сообщения от парней.",
            },
            btn: "Начать сейчас",
            },

            advantages: {
                item1: {
                title: "Удобный интерфейс",
                desc: "Легко настраивайте свой профиль и находите новых знакомых благодаря интуитивно понятному дизайну.",
                },
                item2: {
                title: "Реальные девушки",
                desc: "Все профили проверены, поэтому вы общаетесь только с реальными людьми.",
                },
                item3: {
                title: "Быстрый старт",
                desc: "Регистрация займет всего пару минут, и вы сможете начать общение.",
                },
                item4: {
                title: "Защита данных",
                desc: "Мы заботимся о вашей конфиденциальности, сохраняя вашу личную информацию в безопасности.",
                },
                },
                
                about: {
                title: "О компании",
                subtitle: "Найдите свою вторую половинку",
                desc: "Мы современная платформа, объединяющая людей для общения, дружбы и романтических знакомств. Наша цель — сделать процесс общения максимально удобным и увлекательным. Все девушки, создающие профили, прошли проверку, что гарантирует честность и подлинность общения. Мы постоянно развиваем наш сервис, добавляя новые функции для улучшения вашего опыта общения. Присоединяйтесь к нам и найдите своего идеального собеседника прямо сейчас!",
                stat: {
                user: "Пользыватель",
                chats: "Чаты",
                years: "Годы опыта",
                },
                btn: "Давайте начнем!",
                info: "10m+ Надежные мировые клиенты",
                },
                
                testimonials: {
                title: "Отзывы",
                },
                
                keep: {
                    title: "Поддерживайте связь с сообществом",
                    desc: "Подпишитесь на Luxurgirl сейчас и найдите своего спутника жизни!",
                    btn: "Начать!",
                },
    },

    user: {
        layout: {
            name: 'Ваше имя',
            media: "Медиа",
            description: {
            title: "кое-что обо мне...",
            error: "Не удалось обновить биографию. Попробуйте еще раз.",
            success: "Вы успешно обновили свою биографию!",
            },
            btnVerified: "Проверить профиль",
            verified: "Проверено",
            proccesing: "В процессе",
            },

        profile: {
            about: {
                title: "О нас",
                name: "Имя",
                date: "Дата рождения",
                phone: "Номер телефона",
                city: "Город",
                email: "Email",
                },
                chats: "Последние чаты",
        },

        chat: {
            title: "Чаты",
            recent: "Недавние",
            placeholder: {
            title: "Добро пожаловать,",
            desc: "Выберите чат, чтобы начать обмен сообщениями.",
            },
            profile: "Профиль",
            input: "Введите сообщение",
            btn: "Отправить",
            btnFile: "Отправить файл"
        },

        friends: {
            title: "Друзья",
            table: {
            user: "Пользователь",
            age: "Возраст",
            country: "Страна",
            status: {
            title: "Статус",
            online: "В сети",
            offline: "Оффлайн",
            },
            actions: {
            title: "Действия",
            profile: "Профиль",
            }
            },
        },

        media: {
            title: "Медиа",
            btnUpload: "Загрузить фото",
            btnDelete: "Удалить фото",
            empty: "Нет фото",
        },

        settings: {
            alert: "Заполните свой профиль",
wallet: "Подключить кошелек",
personal: {
title: "Личная информация",
verif: {
btn: "Проверить профиль",
verified: "Проверено",
proccesing: "В процессе",
},
alertVerif: "Завершить проверку",
alertWallet: "Подключить кошелек",
form: {
name: {
label: "Имя",
placeholder: "Введите свое имя",
error: "Введите свое имя",
},
email: {
label: "Войти",
},
day: {
label: "День рождения",
error: "Введите дату рождения",
},
phone: {
label: "Номер телефона",
placeholder: "Введите свой номер телефона",
error: "Введите номер телефона",
},
location: {
country: {
title: "Страна",
placeholder: "Выбрать Страна",
},
state: {
title: "Штат",
placeholder: "Выбрать штат",
},
city: {
title: "Город",
placeholder: "Выбрать город",
},
},
btn: "Сохранить",
}
},
image: {
    title: "Аватар | Изображение обложки",
    avatar: {
    title: "Изменить аватар",
    btn: "Загрузить аватар",
    },
    cover: {
    title: "Изменить изображение обложки",
    btn: "Загрузить изображение обложки",
    },
    },
    changePass: {
    title: "Изменить пароль",
    oldPass: "Старый пароль",
    newPass: "Новый пароль",
    confirmPass: "Подтвердить пароль",
    btn: "Сохранить",
    },
        },

        table: {
            search: {
                placeholder: "Введите имя пользователя",
                btnSearch: "Поиск",
                btnReset: "Сброс",
                filter: "Фильтр",
                close: "Закрыть",
                },
        },
    },

    girlsList: {
        card: {
            profile: "Профиль",
            newChat: "Новый чат",
            verification: "Проверено",
            desc: "Описание:",
            age: "Возраст:",
            },
            filter: {
            title: "Фильтры",
            input: "Поиск по имени",
            age: "Возрастной диапазон",
            country: {
            label: "Страна",
            placeholder: "Выбрать страну",
            },
            verified: "Проверенные профили",
            },
            tinder: {
            age: 'Возраст:',
            left: "Проведите пальцем влево!",
            right: "Проведите пальцем вправо!",
            },
            empty: "Девушки не найдены",
            error: "Ошибка при создании чат-комнаты.",
            errorExisting: "Чат-комната с указанной девушкой уже существует.",
            success: "Чат успешно создан!",
    },

    footer: {
        desc: "Создайте профиль и откройте для себя мир знакомств с интересными и активными девушками, которые ждут вашего сообщения.",        category: {
            title: "Категория",
item1: "Главная",
item2: "Блог",
item3: "О нас",
item4: "Связаться с нами",
        },

        account: {
            title: "Аккаунт",
            item1: "Войти",
            item2: "Форумы",
            item3: "Чаты",
            item4: "Избранное",
            },

            contact: {
                title: "Контакт",
                social: "social -",
                },
    },

    notFound: {
        title: "Страница не найдена",
        btn: "Перейти на главную",
        },

    modal: {
        images: {
            titleAvatar: "Загрузить аватар",
            titleCover: "Загрузить изображение обложки",
            btn: "Нажмите для загрузки",
            success: "файл успешно загружен",
            error: "Загрузка файла не удалась.",
            errorFormat: "Вы можете загрузить только файл JPG/PNG!",
            },
            media: {
            title: "Загрузить изображения мультимедиа",
            btn: "Загрузить",
            },
            verification: {
                title: "Проверка пользователя",
                camera: {
                open: "Открыть камеру",
                close: "Закрыть камеру",
                take: "Сделать селфи",
                },
                selfies: {
                title: "Ваши селфи:",
                delete: "Удалить",
                },
                info: {
                desc1: "Вам нужно будет сделать селфи с паспортом, чтобы подтвердить свою личность. Пожалуйста, следуйте этим простым инструкциям, чтобы фотография была принята:",
                desc2: "После того, как вы сделаете снимок, убедитесь, что изображение соответствует требованиям, и загрузите его для дальнейшей проверки.",
                list: {
                item1: "Держите свой паспорт в руке",
                item2: "Убедитесь, что информация видна",
                item3: "Фотография должна быть четкой и сфокусированной, без размытия.",
                },
                },
                resolution: {
                undefined: "Пожалуйста, предоставьте разрешение на доступ к вашей камере для использования этого приложения.",
                denied: "Доступ к камере запрещен. Пожалуйста, предоставьте разрешение на доступ к вашей камере.",
                btn: "Разрешить камеру",
                },
                btn: "Отправить",
        },
        info: {
            title: "Инструкция",
            welcome: "Добро пожаловать,",
            desc1: "Чтобы продолжить использовать все функции нашего сервиса, выполните несколько важных шагов:",
            step1: "Подключите свой кошелек",
            step2: "Заполните профиль",
            step3: "Проверьте",
            desc2: "После завершения всех шагов вы сможете полностью использовать наш сервис.",
            btn: "Начать шаги",
            }
    },

    wallet: {
        connection: "Подключение...",
        btnMeta: "Подключить Metamask",
        btnTron: "Подключить Tron",
        btnTronLink: "Подключить TronLink",
        wallet: "Кошелек подключен",
        disconnect: "Отключить кошелек",
        errorMeta: "Установите Metamask, чтобы использовать это приложение.",
        errorMob: "Для мобильных устройств требуется специальный браузер dApp.",
        }
};
