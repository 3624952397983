export const GIRLS_ARRAY = [
  {
    name: "Kate Jones",
    age: "31",
    city: "Spain",
    img: require("../assets/img/girls/item-1.jpg"),
    link: "/",
    verification: true,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Antawnia Jamison",
    age: "29",
    city: "England",
    img: require("../assets/img/girls/item-2.jpg"),
    link: "/",
    verification: false,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Stephania Milovic",
    age: "26",
    city: "Czech Republic",
    img: require("../assets/img/girls/item-3.jpg"),
    link: "/",
    verification: false,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Taba Carew",
    age: "32",
    city: "USA",
    img: require("../assets/img/girls/item-4.jpg"),
    link: "/",
    verification: false,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Shen Foster",
    age: "43",
    city: "England",
    img: require("../assets/img/girls/item-5.jpg"),
    link: "/",
    verification: true,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Michellie Jones",
    age: "19",
    city: "France",
    img: require("../assets/img/girls/item-6.jpg"),
    link: "/",
    verification: false,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Melanie Cheney",
    age: "34",
    city: "Norway",
    img: require("../assets/img/girls/item-7.jpg"),
    link: "/",
    verification: false,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
  {
    name: "Keir Pati",
    age: "22",
    city: "Italy",
    img: require("../assets/img/girls/item-8.jpg"),
    link: "/",
    verification: true,
    sortDescription:
      "I will give you a complete account of the system, and expound the actual teachings of the great explorer of human happiness.",
  },
];
