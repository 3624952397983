import React from "react";

const IconSend = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12417 12.5995L15.3649 9.91916C15.8714 9.7016 16.1061 9.11372 15.889 8.60609C15.6719 8.09846 15.0853 7.86331 14.5788 8.08087L7.78223 11H7.98692C8.46257 11 8.8721 11.3364 8.96538 11.8039L9.12417 12.5995Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9966 5.01273L4.36109 9.94482C4.11772 10.0216 3.96433 10.2502 3.99939 10.5766C4.01609 10.7321 4.07576 10.8445 4.13512 10.9062C4.18046 10.9533 4.24976 11 4.39621 11H8.02976C8.50541 11 8.91494 11.3364 9.00822 11.8039L9.67716 15.1558L11.1182 13.9523C11.5068 13.6278 12.0761 13.646 12.4433 13.9946L16.3516 17.7052C16.3575 17.7108 16.3634 17.7165 16.3691 17.7223C16.9135 18.265 17.8421 17.9653 17.9683 17.2068L19.9966 5.01273ZM19.4383 3.0921C20.8398 2.65002 22.2139 3.84605 21.9722 5.29896L19.9368 17.5356C19.5448 19.8925 16.6654 20.8247 14.971 19.1495L12.3111 16.6241C11.3356 17.0461 10.3542 17.5913 9.35977 17.943C9.14803 18.0178 8.91371 18.0202 8.69546 17.943C8.36371 17.8256 8.11814 17.5418 8.04914 17.1961L7.21173 13H4.39621C2.92986 13 2.12952 11.8554 2.01517 10.7906C1.9021 9.73789 2.41498 8.46196 3.76191 8.03709L19.4383 3.0921Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconSend;
