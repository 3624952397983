import React, { useState, useEffect } from "react";
import { Alert, Button } from "antd";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "./Icons";

const PermissionScreen = ({ onPermissionGranted }) => {
  const { t } = useTranslation();
  const basePath = "modal.verification.permission.";

  const [permissionStatus, setPermissionStatus] = useState("undetermined");

  useEffect(() => {
    checkCameraPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCameraPermission = async () => {
    try {
      //ask the permissions api whether we have camera permissions
      const res = await navigator.permissions.query({ name: "camera" });
      if (res.state === "granted") {
        setPermissionStatus("granted");
        onPermissionGranted();
      } else if (res.state === "prompt") {
        // doesnt have permission
        setPermissionStatus("undetermined");
      }
    } catch (error) {
      console.error("Error checking camera permission:", error);
      setPermissionStatus("undetermined");
    }
  };

  const requestPermission = async () => {
    try {
      // Request camera permission by accessing the camera
      await navigator.mediaDevices.getUserMedia({ video: true });
      setPermissionStatus("granted");
      onPermissionGranted();
    } catch (error) {
      console.error("Error requesting camera permission:", error);
      setPermissionStatus("denied");
    }
  };

  return (
    <div className="permission-screen">
      {permissionStatus === "undetermined" ? (
        <div>
          <Alert
            type="info"
            message={t(`${basePath}undetermined`)}
            showIcon
            icon={
              <IconInfoCircle width="22" height="22" fill="var(--color-blue)" />
            }
          />
          <div className="flex justify-center">
            <Button onClick={requestPermission}>{t(`${basePath}btn`)}</Button>
          </div>
        </div>
      ) : permissionStatus === "denied" ? (
        <div>
          <Alert
            type="error"
            message={t(`${basePath}denied`)}
            showIcon
            icon={
              <IconInfoCircle width="22" height="22" fill="var(--color-red)" />
            }
          />
          <div className="flex justify-center">
            <Button onClick={requestPermission}>{t(`${basePath}btn`)}</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PermissionScreen;
