import React, { useState } from "react";
import { Col, Row, Button } from "antd";
import Auth from "../Auth";
import { useTranslation } from "react-i18next";

const Keep = () => {
  const { t } = useTranslation();
  const basePath = "home.keep.";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="keep">
      <section>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="content">
              <h1>{t(`${basePath}title`)}</h1>
              <p>{t(`${basePath}desc`)}</p>
              <div className="flex justify-center mt-30">
                <Button onClick={handleShow}>{t(`${basePath}btn`)}</Button>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* Auth Form Modal */}
      <Auth show={show} handleClose={handleClose} />
    </div>
  );
};

export default Keep;
