import React from "react";

const IconBadgeCheck = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M20.122,9.88l-.442-.442a1.5,1.5,0,0,1-.439-1.06V7.757a3,3,0,0,0-3-3h-.621a1.5,1.5,0,0,1-1.06-.439l-.442-.442a3,3,0,0,0-4.24,0l-.442.442a1.5,1.5,0,0,1-1.06.439H7.757a3,3,0,0,0-3,3v.621a1.5,1.5,0,0,1-.439,1.06l-.442.442a3,3,0,0,0,0,4.24l.442.442a1.5,1.5,0,0,1,.439,1.06v.621a3,3,0,0,0,3,3h.621a1.5,1.5,0,0,1,1.06.439l.442.442a3,3,0,0,0,4.24,0l.442-.442a1.5,1.5,0,0,1,1.06-.439h.621a3,3,0,0,0,3-3v-.621a1.5,1.5,0,0,1,.439-1.06l.442-.442A3,3,0,0,0,20.122,9.88Zm-4.592.65-4,4a.748.748,0,0,1-1.06,0l-2-2a.75.75,0,0,1,1.06-1.06L11,12.939,14.47,9.47a.75.75,0,0,1,1.06,1.06Z"
      />
    </svg>
  );
};

export default IconBadgeCheck;
