import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Empty, Image, Row, Tooltip } from "antd";
import UserLayout from "../../components/UserLayout";
import { IconTrash, IconUpload } from "../../utils/Icons";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import {
  getUserInfo,
  getUserInfoGirl,
  updateUserInfo,
  updateUserInfoGirl,
} from "../../utils/Api";
import UploadMediaModal from "../../components/Modal/UploadMediaModal";

const Media = () => {
  const { t } = useTranslation();
  const basePath = "user.media.";

  const { user_info, token, email, girl, setUserInfo } =
    useContext(UserTypeContext);

  const [userInfo, setUserInfoState] = useState(user_info);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);

  const handleDeletePhoto = (index) => {
    const updatedPhotos = userInfo.photos.filter((_, i) => i !== index);
    setUserInfoState((prevInfo) => ({
      ...prevInfo,
      photos: updatedPhotos,
    }));
  };

  const fetchUserInfo = useCallback(async () => {
    const fetchUser = girl ? getUserInfoGirl : getUserInfo;
    const res = await fetchUser(email, token);

    setUserInfo(res);
    setUserInfoState(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [girl, token, setUserInfo]);

  useEffect(() => {
    const updateUser = girl ? updateUserInfoGirl : updateUserInfo;
    updateUser(userInfo, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.photos]);

  return (
    <UserLayout userInfo={user_info} className={"page media"}>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <h4 className="title-block">
            {t(`${basePath}title`)}
            <Button onClick={() => setIsMediaModalOpen(true)}>
              <IconUpload width="20" height="20" fill="var(--color-main)" />
              {t(`${basePath}btnUpload`)}
            </Button>
          </h4>

          <div className="media-list">
            {userInfo?.photos && userInfo.photos.length > 0 ? (
              <Image.PreviewGroup>
                {userInfo.photos.map((photo, index) => (
                  <div className={"media-item"} key={index}>
                    <Image
                      width={200}
                      height={200}
                      src={photo}
                      alt={`media-${index}`}
                    />
                    <Tooltip title={t(`${basePath}btnDelete`)}>
                      <Button onClick={() => handleDeletePhoto(index)}>
                        <IconTrash
                          width="22"
                          height="22"
                          fill="var(--color-red)"
                        />
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </Image.PreviewGroup>
            ) : (
              <Empty description={t(`${basePath}empty`)} />
            )}
          </div>
        </Col>
      </Row>

      <UploadMediaModal
        girl={girl}
        fetchUserInfo={fetchUserInfo}
        token={token}
        setUserInfo={setUserInfoState}
        isModalOpen={isMediaModalOpen}
        handleCancel={() => setIsMediaModalOpen(false)}
        userInfo={userInfo}
      />
    </UserLayout>
  );
};

export default Media;
