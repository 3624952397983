export const TRANSLATIONS_EN = {
    navBar: {
        lang: {
            'en-US': "English",
            'de-DE': "German",
            'es-ES': "Spanish",
            'ru-RU': "Russian",
        },
        profile: "Profile",
        exit: 'Log out',
        logIn: "Log in",
        menu: {
            profil: "Profile",
            chats: "Chats",
            setting: "Settings",
            exit: 'Log out',
        }
    },

    sideBar: {
        menu: {
            profil: "Profile",
            chats: "Chats",
            friend: "Friends",
            media: "Media",
            setting: "Settings",
            girls: "Girls",
            exit: 'Log out',
        },
    },

    auth: {
        title: "Authorisation",
        loginTab: 'Login',
        signUpTab: 'Registration',
        form: {
            email: {
                label: "Login",
                placeholder: "Enter your login",
            },
            pass: {
                label: "Password",
                placeholder: "Enter your Password",
            },
            passConfirm: {
                label: "Confirm password",
                placeholder: "Enter your Password",
            },
            type: {
                label: "Account type",
                placeholder: "Select an account type",
                item: {
                    user: 'User',
                    girl: 'Participant',
                }
            },
            remember: "Remember Me",
            btnLog: 'Log in',
            btnSig: 'Sign up'
        },
        success: {
            registrationSuccess: "You have successfully registered! Please log in to your account.",
        },
        errors: {
            userNotFound: "User not found!",
            serverError: "Server error",
            missingFields: 'Please fill in all fields!',
            passwordMismatch: "Passwords don't match",
            emailInUse: "Failed! Email is already in use!",
        },
    },

    home: {
        banner: {
            title: "Meet charming girls and start hooking up right now!",
            desc: 'Create a profile and discover the world of dating with interesting and active girls who are waiting for your message.',
            btn: "Get Started!",
        },

        cta: {
            item1: {
                title: "Meet the girls!",
                desc: "Create a profile and start chatting with girls who are waiting for your message.",
            },
            item2: {
                title: "Find new acquaintances!",
                desc: "Create a profile and start receiving messages from guys.",
            },
            btn: "Start Now",
        },

        advantages: {
            item1: {
                title: "User-friendly interface",
                desc: "Easily customize your profile and find new acquaintances thanks to the intuitive design.",
            },
            item2: {
                title: "Real girls",
                desc: "All profiles are verified, so you only chat with real people.",
            },
            item3: {
                title: "Quick Start",
                desc: "It only takes a couple minutes to sign up and you can start chatting.",
            },
            item4: {
                title: "Data protection",
                desc: "We care about your privacy by keeping your personal information safe.",
            },
        },

        about: {
            title: "About Company",
            subtitle: "Find Your Life Partner",
            desc: "We are a modern platform that unites people for communication, friendship and romantic dating. Our goal is to make the process of communication as convenient and fun as possible. All girls who create profiles have been verified, which guarantees honesty and authenticity of communication. We are constantly developing our service, adding new features to improve your chatting experience. Join us and find your perfect companion right now!",
            stat: {
                user: "User",
                chats: "Chats",
                years: "Years Of Experience",
            },
            btn: "Get Started!",
            info: "10m+ Trusted Global clients",
        },

        testimonials: {
            title: "Testimonials",
        },

        keep: {
            title: "Keep in Touch with the Community",
            desc: "Subscribe to Luxurgirl now and find your Life Partner!",
            btn: "Get Started!",
        },
    },

    user: {
        layout: {
            name: 'Your name',
            media: "Media",
            description: {
                title: "something about me...",
                error: "Failed to update biography. Please try again.",
                success: "You have successfully updated your biography!",
            },
            btnVerified: "Verify profile",
            verified: "Verified",
            proccesing: "In Progres",
        },

        profile: {
            about: {
                title: "About",
                name: "Name",
                date: "Birthdate",
                phone: "Phone No",
                city: "City",
                email: "Email",
            },
            chats: "Latest chats",
        },

        chat: {
            title: "Chats",
            recent: "Recent",
            placeholder: {
                title: "Welcome,",
                desc: "Please select a chat to start messaging.",
            },
            profile: "Profile",
            input: "Enter message",
            btn: "Send",
            btnFile: "Send File"
        },

        friends: {
            title: "Friends",
            table: {
                user: "User",
                age: "Age",
                country: "Country",
                status: {
                    title: "Status",
                    online: "Online",
                    offline: "Offline",
                },
                actions: {
                    title: "Actions",
                    profile: "Profile",
                }
            },
        },

        media: {
            title: "Media",
            btnUpload: "Upload photos",
            btnDelete: "Delete photo",
            empty: 'No pictures',
        },

        settings: {
            alert: "Please Fill your profile",
            wallet: "Connect Wallet",
            personal: {
                title: "Personal info",
                verif: {
                    btn: "Verify profile",
                    verified: "Verified",
                    proccesing: "In Progres",
                },
                alertVerif: "Complete verification",
                alertWallet: "Connect your wallet",
                form: {
                    name: {
                        label: "Name",
                        placeholder: "Enter your Name",
                        error: "Please enter your name",
                    },
                    email: {
                        label: "Login",
                    },
                    birthday: {
                        label: "Birthday",
                        error: "Please enter your date of birth",
                    },
                    phone: {
                        label: "Phone number",
                        placeholder: "Enter your phone number",
                        error: "Please enter a phone number",
                    },
                    location: {
                        country: {
                            title: "Country",
                            placeholder: "Select Country",
                        },
                        state: {
                            title: "State",
                            placeholder: "Select State",
                        },
                        city: {
                            title: "City",
                            placeholder: "Select City",
                        },
                    },
                    btn: "Save",
                }
            },
            image: {
                title: "Avatar | Cover Image",
                avatar: {
                    title: "Change Avatar",
                    btn: "Upload Avatar",
                },
                cover: {
                    title: "Change Cover Image",
                    btn: "Upload Cover Image",
                },
            },
            changePass: {
                title: "Change Password",
                oldPass: "Old password",
                newPass: "New password",
                confirmPass: "Confirm password",
                btn: "Save",
            },
        },

        table: {
            search: {
                placeholder: "Enter a user name",
                btnSearch: "Search",
                btnReset: "Reset",
                filter: "Filter",
                close: "Close",
            },
        },
    },

    girlsList: {
        card: {
            profile: "Profile",
            newChat: "New Chat",
            verification: "Verified",
            desc: "Description:",
            age: "Age:",
        },
        filter: {
            title: "Filters",
            input: "Search by name",
            age: "Age Range",
            country: {
                label: "Country",
                placeholder: "Select country",
            },
            verified: "Verified Profiles",
        },
        tinder: {
            age: 'Age:',
            left: "Swipe left!",
            right: "Swipe right!",
        },
        empty: "No girls found",
        error: "Error when creating a chat room.",
        errorExisting: "A chat room with the girl in question already exists.",
        success: "Chat has been successfully created!",
    },

    footer: {
        desc: "Create a profile and discover the world of dating with interesting and active girls who are waiting for your message.",
        category: {
            title: "Category",
            item1: "Home",
            item2: "Blog",
            item3: "About us",
            item4: "Contact us",
        },

        account: {
            title: "Account",
            item1: "Sign In",
            item2: "Forums",
            item3: "Chats",
            item4: "Favourite",
        },

        contact: {
            title: "Contact",
            social: "social -",
        },
    },

    notFound: {
        title: "Page not found",
        btn: "Go to home",
    },

    modal: {
        images: {
            titleAvatar: "Upload Avatar",
            titleCover: "Upload Cover Image",
            btn: "Click to Upload",
            success: "file uploaded successfully",
            error: "File upload failed.",
            errorFormat: "You can only upload JPG/PNG file!",
        },
        media: {
            title: "Upload Media Images",
            btn: "Upload",
        },
        verification: {
            title: "User verification",
            camera: {
                open: "Open Camera",
                close: "Close Camera",
                take: "Take Selfie",
            },
            selfies: {
                title: "Your selfies:",
                delete: "Delete",
            },
            info: {
                desc1: "You will need to take selfies with your passport to verify your identity. Please follow these simple instructions for the photo to be accepted:",
                desc2: "Once you take the shot, make sure the image meets the requirements and upload it for further verification.",
                list: {
                    item1: "Hold your passport in your hand",
                    item2: "Make sure the information is visible",
                    item3: "The photo should be clear and focused, with no blurring.",
                },
            },
            permission: {
                undetermined: "Please grant permission to access your camera to use this app.",
                denied: "Camera access is denied. Please grant permission to access your camera.",
                btn: "Allow camera",
            },
            btn: "Send",
        },
        info: {
            title: "Instruction",
            welcome: "Welcome,",
            desc1: "To continue utilizing all the features of our service, follow a few important steps:",
            step1: "Connect your wallet",
            step2: "Fill out the profile",
            step3: "Verify",
            desc2: "Once all steps have been completed, you will be able to fully to fully utilize our service.",
            btn: "Start the steps",
        }
    },

    wallet: {
        connection: "Connection...",
        btnMeta: "Connect Metamask",
        btnTron: "Connect Tron",
        btnTronLink: "Connect TronLink",
        wallet: "Wallet connected",
        disconnect: "Disable Wallet",
        errorMeta: "Please install Metamask to use this application.",
        errorMob: 'A special dApp browser is required for mobile devices.',
    }
};
