import React from "react";

const IconMail = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V9C21.678 9.27205 21.3544 9.525 21.0277 9.7804C20.8751 9.8997 20.7218 10.0195 20.5677 10.1421C20.3946 10.2797 20.2044 10.4295 20 10.5884V16C20 17.1046 19.1046 18 18 18H6C4.89543 18 4 17.1046 4 16V10.5884C3.79559 10.4295 3.60535 10.2797 3.4323 10.1421C3.27817 10.0195 3.12488 9.89969 2.97227 9.78039C2.64557 9.525 2.32198 9.27204 2 9Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V9C21.5276 9.39912 21.0518 9.75715 20.5677 10.1421C19.9101 10.665 19.0043 11.3643 18.0162 12.0655C17.0319 12.7641 15.9469 13.4782 14.9338 14.0218C13.9723 14.5377 12.8998 15 12 15C11.1002 15 10.0277 14.5377 9.06624 14.0218C8.05311 13.4782 6.96807 12.7641 5.98375 12.0655C4.99567 11.3643 4.08989 10.665 3.4323 10.1421C2.94822 9.75715 2.47239 9.39912 2 9V8ZM4 8.03023C4.17276 8.17188 4.40277 8.35854 4.67708 8.57667C5.31636 9.08501 6.19183 9.76072 7.14125 10.4345C8.09443 11.1109 9.10314 11.7718 10.0119 12.2595C10.9723 12.7748 11.6498 13 12 13C12.3502 13 13.0277 12.7748 13.9881 12.2595C14.8969 11.7718 15.9056 11.1109 16.8588 10.4345C17.8082 9.76072 18.6836 9.08501 19.3229 8.57667C19.5972 8.35854 19.8272 8.17188 20 8.03023V8C20 6.89543 19.1046 6 18 6H6C4.89543 6 4 6.89543 4 8V8.03023Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconMail;
