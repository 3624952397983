import React from "react";

const IconUpload = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M4 14C3.448 14 3 14.448 3 15V17C3 19.209 4.791 21 6.99999 21H17C19.209 21 21 19.209 21 17V15C21 14.448 20.552 14 20 14C19.448 14 19 14.448 19 15V17C19 18.105 18.105 19 17 19H6.99999C5.895 19 5 18.105 5 17V15C5 14.448 4.552 14 4 14Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12 2C11.738 2 11.477 2.08601 11.281 2.281L5.59399 7.99998L7 9.40599L11 5.43798V16C11 16.552 11.448 17 12 17C12.552 17 13 16.552 13 16V5.43798L17 9.40599L18.406 7.99998L12.719 2.281C12.523 2.08601 12.262 2 12 2Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconUpload;
