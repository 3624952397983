import React from "react";
import { Form, Input, InputNumber } from "antd";
import {
  IconAngleDownSquare,
  IconAngleUpSquare,
  IconArrowDown,
  IconArrowUp,
} from "../../../utils/Icons";

const InputNumberUi = ({
  name,
  id,
  label,
  icon,
  errorMess,
  formItemClassName,
  required = true,
  ...props
}) => {
  return (
    <Form.Item
      name={name}
      rules={[{ required: required, message: errorMess }]}
      className={formItemClassName}
    >
      <label htmlFor={name}>
        {label} {icon}
      </label>
      <Input
        name={name}
        id={id}
        {...props}
        // controls={{
        //   upIcon: (
        //     <IconArrowUp width="14" height="14" fill="var(--color-font)" />
        //   ),
        //   downIcon: (
        //     <IconArrowDown width="14" height="14" fill="var(--color-font)" />
        //   ),
        // }}
      />
    </Form.Item>
  );
};

export default InputNumberUi;
