import React from "react";

const IconEyeSlash = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L17.0031 5.58273C15.6417 4.66636 13.9708 4 12 4C8.58095 4 6.07848 5.99685 4.49074 7.84921C3.68919 8.78435 3.09153 9.71488 2.69426 10.4101C2.49499 10.7588 2.34449 11.0512 2.24256 11.2594C2.18673 11.3734 2.13181 11.4879 2.08155 11.6045L2.08085 12.3939V11.6061C1.97305 11.8576 1.97305 12.1424 2.08085 12.3939L2.08183 12.3962C2.10525 12.4504 2.12993 12.5041 2.15492 12.5576C2.2004 12.655 2.26681 12.7929 2.35419 12.9632C2.52872 13.3034 2.78815 13.7756 3.13305 14.3154C3.67421 15.1623 4.43949 16.1973 5.43488 17.1509L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L7.51257 17.9016C7.57536 17.8533 7.63336 17.7966 7.68496 17.7319C7.68819 17.7278 7.69137 17.7238 7.69452 17.7197L17.734 7.68021C17.7893 7.6358 17.8406 7.5848 17.8869 7.52735L19.7071 5.70711ZM15.5557 7.03006C14.5282 6.40603 13.3453 6 12 6C9.41905 6 7.42152 7.50315 6.00926 9.15079C5.31081 9.96565 4.78347 10.7851 4.43074 11.4024C4.29715 11.6362 4.18956 11.8393 4.10805 12L4.13367 12.0502C4.28437 12.344 4.51281 12.7602 4.81839 13.2385C5.31108 14.0096 5.99018 14.9195 6.84946 15.7363L15.5557 7.03006Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17256 9.17157C10.4916 7.85366 12.5021 7.64765 14.0372 8.55353L12.5216 10.0679C11.8539 9.88971 11.1119 10.0624 10.588 10.5858C10.0641 11.1092 9.89131 11.8506 10.0696 12.5178L8.554 14.0321C7.64735 12.4982 7.85354 10.4895 9.17256 9.17157ZM19.1986 8.99777C19.4268 8.84177 19.697 8.7938 19.9492 8.8418C20.2005 8.8888 20.4497 9.01977 20.6058 9.24777C21.2664 10.2168 21.6887 11.0538 21.9189 11.5918C22.027 11.8428 22.027 12.1528 21.9189 12.4038C21.8108 12.6558 21.6267 13.0638 21.3254 13.5918C20.826 14.4638 20.2125 15.3368 19.5109 16.1538C17.4612 18.5438 14.966 19.9978 12.0045 19.9978C11.6232 19.9978 11.0477 19.9328 10.4412 19.8418C9.89374 19.7598 9.51442 19.2628 9.59649 18.7168C9.67856 18.1698 10.176 17.7908 10.7224 17.8728C11.2299 17.9488 11.7563 17.9978 12.0045 17.9978C14.2975 17.9978 16.3062 16.8268 18.0096 14.8418C18.6221 14.1278 19.1336 13.3598 19.5739 12.5918C19.7113 12.3502 19.7795 12.2127 19.8572 12.0561C19.8667 12.037 19.8763 12.0176 19.8862 11.9978C19.8717 11.9692 19.8655 11.949 19.8591 11.9282C19.8519 11.9045 19.8444 11.8801 19.8241 11.8418C19.5819 11.3818 19.2877 10.8708 18.9484 10.3728C18.6361 9.91677 18.7412 9.30877 19.1986 8.99777Z"
        fill={fill}
        fillOpacity={0.4}
      />
    </svg>
  );
};

export default IconEyeSlash;
