import React from "react";

const IconShield = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C5.55229 5 6 5.44772 6 6V8C6 10.9301 6.02616 16.617 12 19.8721C17.9738 16.617 18 10.9301 18 8V6C18 5.44772 18.4477 5 19 5C19.5523 5 20 5.44772 20 6V8.03234C20.0001 11.0493 20.0003 18.1179 12.4472 21.8944L12 22.118L11.5528 21.8944C3.99967 18.1179 3.9999 11.0493 4 8.03234L4 6C4 5.44772 4.44772 5 5 5Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.91199L19.3939 5.08081C19.9016 5.29837 20.1367 5.88624 19.9192 6.39387C19.7016 6.9015 19.1137 7.13666 18.6061 6.9191L12 4.08792L5.39393 6.9191C4.8863 7.13666 4.29842 6.9015 4.08087 6.39387C3.86331 5.88624 4.09846 5.29837 4.60609 5.08081L12 1.91199Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconShield;
