import React from "react";

const IconClose = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M7 3C4.791 3 3 4.791 3 7V17C3 19.209 4.791 21 7 21H17C19.209 21 21 19.209 21 17V7C21 4.791 19.209 3 17 3H7ZM7 5H17C18.105 5 19 5.895 19 7V17C19 18.105 18.105 19 17 19H7C5.895 19 5 18.105 5 17V7C5 5.895 5.895 5 7 5Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M9.0086 8C8.75333 8 8.4871 8.08588 8.29166 8.2806C7.90278 8.67104 7.90278 9.32609 8.29166 9.71653L10.5661 11.9943L8.29166 14.272C7.90278 14.6624 7.90278 15.3175 8.29166 15.7079C8.68154 16.0974 9.33565 16.0974 9.72553 15.7079L12 13.4302L14.2745 15.7079C14.6643 16.0974 15.3185 16.0974 15.7083 15.7079C16.0972 15.3175 16.0972 14.6624 15.7083 14.272L13.4339 11.9943L15.7083 9.71653C16.0972 9.32609 16.0972 8.67104 15.7083 8.2806C15.5129 8.08588 15.2467 8 14.9914 8C14.7361 8 14.4699 8.08588 14.2745 8.2806L12 10.5583L9.72553 8.2806C9.53009 8.08588 9.26386 8 9.0086 8Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconClose;
