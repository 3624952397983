import React from "react";

const IconKeyFill = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M11 12.9999C9.98368 14.0156 4 20 2.75903 21.2405C3.77171 22.2532 5.79705 22.2532 6.80973 21.2405C7.8224 20.2279 7.19312 18.8314 7.8224 18.2026C8.83507 17.1899 9.84775 18.2026 10.8604 17.1899C11.6998 16.3507 12.3845 16.0524 13 15.9962C13.4417 15.9559 13.8281 16.0351 14.1133 16.1054C13.0259 15.0213 11 12.9999 11 12.9999Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83509 6.04569C8.83509 6.04569 12.6793 1.97079 12.9808 2.00016C17.781 2.468 21.5318 6.21887 21.9998 11.0188C22.0293 11.3206 17.9491 15.1646 17.9491 15.1646C16.4301 16.6836 15.4176 16.4304 14.405 16.1773C14.3047 16.1522 14.2045 16.1272 14.1038 16.1038L11 13L2.75927 21.2407C2.75934 21.2408 2.75919 21.2407 2.75927 21.2407C1.74659 20.2281 1.74637 18.2026 2.76269 17.1869L6.80974 13.1393C8.32888 11.6177 8.07554 10.6037 7.82235 9.59035C7.5692 8.57715 7.31621 7.56454 8.83509 6.04569ZM15.639 8.36062C15.4413 8.16315 15.1704 8.07606 14.9111 8.07606C14.652 8.07606 14.3811 8.16315 14.1833 8.36062C13.7879 8.75657 13.7879 9.42085 14.1833 9.8168C14.5788 10.2117 15.2436 10.2117 15.639 9.8168C16.0345 9.42085 16.0345 8.75657 15.639 8.36062Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconKeyFill;
