import React, { useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import { IconBuilding, IconGeo, IconGlobe } from "../../../utils/Icons";

const LocationSelectUi = ({ user_info, onLocationChange }) => {
  const { t } = useTranslation();
  const basePath = "user.settings.personal.form.location.";

  const [location, setLocation] = useState({
    country: user_info.location?.country || "",
    state: user_info.location?.state || "",
    city: user_info.location?.city || "",
    countryId: user_info.location?.countryId || 0,
    stateId: user_info.location?.stateId || 0,
  });

  const updateLocation = (updatedFields) => {
    const newLocation = { ...location, ...updatedFields };
    setLocation(newLocation);
    onLocationChange(newLocation);
  };

  return (
    <Row>
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <label>
          {t(`${basePath}country.title`)}
          <IconGlobe width="22" height="22" fill="var(--color-main)" />
        </label>
        <CountrySelect
          value={location.countryId || undefined}
          showFlag={false}
          onChange={(e) => {
            updateLocation({
              country: e.name,
              countryId: e.id,
              state: "",
              stateId: 0,
              city: "",
            });
          }}
          disabled={location.country}
          placeHolder={location.country || t(`${basePath}country.placeholder`)}
        />
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <label>
          {t(`${basePath}state.title`)}
          <IconGeo width="22" height="22" fill="var(--color-main)" />
        </label>
        <StateSelect
          value={location.stateId || undefined}
          countryid={location.countryId}
          onChange={(e) => {
            updateLocation({
              state: e.name,
              stateId: e.id,
              city: "",
            });
          }}
          disabled={location.stateId}
          placeHolder={location.state || t(`${basePath}state.placeholder`)}
        />
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <label>
          {t(`${basePath}city.title`)}
          <IconBuilding width="22" height="22" fill="var(--color-main)" />
        </label>
        <CitySelect
          value={location.city || undefined}
          countryid={location.countryId}
          stateid={location.stateId}
          onChange={(e) => {
            updateLocation({
              city: e.name,
            });
          }}
          disabled={location.city}
          placeHolder={location.city || t(`${basePath}city.placeholder`)}
        />
      </Col>
    </Row>
  );
};

export default LocationSelectUi;
