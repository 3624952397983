import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Button, Image, Tooltip, Upload } from "antd";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getChatInfo,
  getUserInfo,
  getUserInfoGirl,
  setReadMessage,
} from "../../../utils/Api";
import { IconLink, IconPerson, IconSend } from "../../../utils/Icons";
import { UserTypeContext } from "../../../contexts/UserTypeContext";
import axios from "axios";

const socket = io.connect("https://girlluxury.xyz/");

const ChatUi = ({ size, girl }) => {
  const { t } = useTranslation();
  const basePath = "user.chat.";

  const { token, setEmailGirl, email } = useContext(UserTypeContext);
  const [user_info, setUserInfoState] = useState([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const chatContainerRef = useRef(null);

  const [chats, setChats] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [chatMessage, setChatMessage] = useState("");

  const [receiverId, setReceiverId] = useState(null);
  const [photoTitle, setPhotoTitle] = useState(null);
  const [receiverEmail, setReceiverEmail] = useState(null);
  const [receiverName, setReceiverName] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState("");

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const fetchUserInfo = useCallback(async () => {
    const fetchUser = girl ? getUserInfoGirl : getUserInfo;
    const res = await fetchUser(email, token);
    setUserInfoState(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = () => {
    if (!receiverId || !chatMessage || !chatId) return;

    socket.emit("send_message", {
      chatId,
      sender_id: user_info.id,
      sender_name: user_info.name,
      receiver_id: receiverId,
      receiver_name: receiverName,
      messageId: Date.now(),
      message: chatMessage,
      timestamp: new Date().toLocaleString(),
      massageType: null,
      girl: girl,
    });

    setChatMessage("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const handleMessage = (data) => setReceivedMessage(data);

    socket.on("receive_message", handleMessage);

    return () => {
      socket.off("receive_message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (receivedMessage.chatId === chatId) {
      setReadMessage(user_info.id, chatId, girl, token);
      setChats((prevChats) => [...prevChats, receivedMessage]);
    }
    fetchUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const getChat = async (e) => {
    setChats([]);
    await setReadMessage(user_info.id, e.dataset.chatid, girl, token);
    await fetchUserInfo();
    await getChatInfo(e.dataset.chatid, token).then((r) => {
      setChats(r);
      setChatId(e.dataset.chatid);
      setReceiverId(e.dataset.recid);
      setReceiverName(e.dataset.recname);
      setReceiverEmail(e.dataset.reemil);
      user_info.chatRooms.find((el) => {
        if (String(e.dataset.recid) === String(el.info.id)) {
          setPhotoTitle(el.info.photo_title);
        }
      });
    });
  };

  const handleProfileView = () => {
    setEmailGirl(receiverEmail);
    navigate("/userView");
  };

  const handleUploadChange = (file) => {
    const URL = "https://girlluxury.xyz/sendFile";

    const formData = new FormData();
    formData.append("photo", file);
    const res = axios.post(URL, formData, {
      headers: {
        "x-access-token": token,
        type: "file",
        "Content-Type": "multipart/form-data",
      },
    });
    const promise = new Promise((resolve, reject) => {
      resolve(res);
    });

    promise.then((res) => {
      if (!receiverId || !chatId) return;

      socket.emit("send_message", {
        chatId,
        sender_id: user_info.id,
        sender_name: user_info.name,
        receiver_id: receiverId,
        receiver_name: receiverName,
        messageId: Date.now(),
        message: chatMessage,
        massageType: `https://girlluxury.xyz/${res.data.name}`,
        timestamp: new Date().toLocaleString(),
        girl: girl,
      });

      setChatMessage("");
      fetchUserInfo();
      setLoading(false);
    });
  };

  const beforeUpload = (file) => {
    setLoading(true);
    handleUploadChange(file);
    return false;
  };

  // Helper function to determine file type
  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  };

  const isVideo = (url) => {
    return /\.(mp4|webm|ogg)$/i.test(url);
  };

  return (
    <div className={`chats ${size}`}>
      <div className="chat-user">
        <span className="subtitle">{t(`${basePath}recent`)}</span>
        <div className="users">
          {user_info?.chatRooms
            ?.sort((a, b) => b.lastmessage - a.lastmessage)
            .map((el) => (
              <Button
                key={el.chatId}
                data-chatid={el.chatId}
                data-recname={el.receiver_name}
                data-recid={el.receiver_id}
                data-reemil={el.info.email}
                onClick={(e) => getChat(e.target)}
              >
                <span
                  data-chatid={el.chatId}
                  data-recname={el.receiver_name}
                  data-recid={el.receiver_id}
                  data-reemil={el.info.email}
                >
                  <img
                    src={el.info.photo_title}
                    data-chatid={el.chatId}
                    data-recname={el.receiver_name}
                    data-recid={el.receiver_id}
                    data-reemil={el.info.email}
                    alt={el.receiver_name}
                  />
                  {el.receiver_name}
                  {el.unreadMessage === 0 ? null : <s>{el.unreadMessage}</s>}
                </span>
              </Button>
            ))}
        </div>
      </div>

      <div className="chat-content">
        {!chatId ? (
          <div className="empty-chat">
            <h4>
              {t(`${basePath}placeholder.title`)} {user_info.name}!
            </h4>
            <p>{t(`${basePath}placeholder.desc`)}</p>
          </div>
        ) : (
          <>
            <div className="content-top">
              <div className="name">
                <img src={photoTitle} alt={receiverName} />
                <span>
                  {receiverName} <s>Online</s>
                </span>
              </div>
              <div className="actions">
                <Tooltip title={t(`${basePath}profile`)}>
                  <Button onClick={() => handleProfileView(user_info)}>
                    <IconPerson
                      width="20"
                      height="20"
                      fill="var(--color-font)"
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="chat-container" ref={chatContainerRef}>
              {chats.map((message) => (
                <div
                  key={message.messageId}
                  className={`chat-message ${
                    Number(message.sender_id) === user_info.id
                      ? "sent"
                      : "received"
                  }`}
                >
                  {message.message ? (
                    <span className="message">{message.message}</span>
                  ) : null}

                  {message.massageType ? (
                    isImage(message.massageType) ? (
                      <span className="message">
                        <Image width={200} src={message.massageType} />
                      </span>
                    ) : isVideo(message.massageType) ? (
                      <span className="message">
                        <video controls style={{ width: "200px" }}>
                          <source src={message.massageType} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </span>
                    ) : (
                      <a
                        target="_blunk"
                        href={message.massageType}
                        className="message"
                      >
                        Download File
                      </a>
                    )
                  ) : null}

                  <span className="metadata">{message.timestamp}</span>
                </div>
              ))}
            </div>

            <div className="input-container">
              <input
                type="text"
                placeholder={t(`${basePath}input`)}
                value={chatMessage}
                onChange={(e) => setChatMessage(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <Tooltip title={t(`${basePath}btn`)}>
                <Button onClick={sendMessage}>
                  <IconSend width="24" height="24" fill="var(--color-main)" />
                </Button>
              </Tooltip>
              <Tooltip title={t(`${basePath}btnFile`)}>
                <Upload
                  name="avatar"
                  type="file"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  <Button loading={loading}>
                    <IconLink width="24" height="24" fill="var(--color-main)" />
                  </Button>
                </Upload>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatUi;
