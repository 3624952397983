import React from "react";

const IconInfoCircle = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M11.999 2C6.47622 2 1.99902 6.477 1.99902 12C1.99902 17.523 6.47622 22 11.999 22C17.5218 22 21.999 17.523 21.999 12C21.999 6.477 17.5218 2 11.999 2ZM11.999 4C16.4173 4 19.999 7.582 19.999 12C19.999 16.418 16.4173 20 11.999 20C7.58072 20 3.99902 16.418 3.99902 12C3.99902 7.582 7.58072 4 11.999 4Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12 7C11.4477 7 11 7.448 11 8C11 8.552 11.4477 9 12 9C12.5523 9 13 8.552 13 8C13 7.448 12.5523 7 12 7ZM12 10C11.4477 10 11 10.448 11 11V16C11 16.552 11.4477 17 12 17C12.5523 17 13 16.552 13 16V11C13 10.448 12.5523 10 12 10Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconInfoCircle;
