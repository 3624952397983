import React from "react";

const IconChevronLeft = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M14.9583 5.82652C14.9083 5.57652 14.7823 5.32452 14.5533 5.17152C14.0953 4.86552 13.4573 4.99351 13.1523 5.45251L9.16734 11.4435C8.94434 11.7785 8.94434 12.2315 9.16734 12.5665L13.1523 18.5575C13.4573 19.0165 14.0953 19.1445 14.5533 18.8385C15.0113 18.5325 15.1393 17.8935 14.8333 17.4345L11.2223 12.0045L14.8333 6.57551C14.9863 6.34651 15.0083 6.07752 14.9583 5.82652Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.168 15.5548L9.16797 12.5548C8.94404 12.2189 8.94404 11.7813 9.16797 11.4454L11.168 8.44543L11.168 15.5548Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChevronLeft;
