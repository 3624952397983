import React from "react";

const IconTelephone = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M7.49999 3C5.448 3 3 5.448 3 7.5C3 9.682 5.039 13.477 7.281 15.719C7.487 15.924 8.076 16.513 8.281 16.719C10.523 18.961 14.318 21 16.5 21C18.552 21 21 18.552 21 16.5C21 14.448 18.552 12 16.5 12C16.09 12 15.668 12.163 14.875 12.531C14.802 12.565 14.192 12.864 14.031 12.938C12.915 12.631 11.403 11.098 11.062 9.93799C11.139 9.76799 11.44 9.187 11.469 9.125C11.852 8.313 12 7.915 12 7.5C12 5.448 9.55199 3 7.49999 3ZM7.49999 5C8.44799 5 9.99999 6.552 9.99999 7.5C9.99999 7.475 9.86599 7.83701 9.65599 8.28101C9.628 8.34101 9.29598 8.98401 9.21898 9.15601C8.98298 9.68401 8.954 9.84999 9.031 10.219C9.45099 12.22 11.83 14.611 13.812 14.969C14.169 15.033 14.257 15.001 14.812 14.75C14.968 14.68 15.649 14.376 15.719 14.344C16.159 14.139 16.521 14 16.5 14C17.448 14 19 15.552 19 16.5C19 17.448 17.448 19 16.5 19C14.938 19 11.621 17.184 9.71898 15.281C9.51298 15.076 8.92398 14.487 8.71898 14.281C6.81599 12.379 5 9.062 5 7.5C5 6.552 6.55199 5 7.49999 5Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconTelephone;
