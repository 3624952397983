import React from "react";

const IconChevronDown = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M5.82652 9.04169C5.57652 9.09169 5.32452 9.21768 5.17152 9.44668C4.86552 9.90468 4.99351 10.5427 5.45251 10.8477L11.4435 14.8327C11.7785 15.0557 12.2315 15.0557 12.5665 14.8327L18.5575 10.8477C19.0165 10.5427 19.1445 9.90468 18.8385 9.44668C18.5325 8.98868 17.8935 8.86069 17.4345 9.16669L12.0045 12.7777L6.57551 9.16669C6.34651 9.01369 6.07752 8.99169 5.82652 9.04169Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5547 12.832L12.5547 14.832C12.2188 15.056 11.7812 15.056 11.4453 14.832L8.44531 12.832L15.5547 12.832Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChevronDown;
