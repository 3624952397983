import React from "react";
import { DatePicker, Form } from "antd";
import {
  IconCalendar,
  IconChevronDoubleLeft,
  IconChevronDoubleRight,
  IconChevronLeft,
  IconChevronRight,
  IconClose,
} from "../../../utils/Icons";

const DatePickerUi = ({ name, id, label, format, errorMess, ...props }) => {
  return (
    <Form.Item name={name} rules={[{ required: true, message: errorMess }]}>
      <label htmlFor={name}>
        {label} <IconCalendar width="22" height="22" fill="var(--color-main)" />
      </label>
      <DatePicker
        name={name}
        id={id}
        format={format}
        suffixIcon={false}
        clearIcon={<IconClose width="20" height="20" fill="var(--color-red)" />}
        nextIcon={
          <IconChevronRight width="17" height="17" fill="var(--color-font)" />
        }
        prevIcon={
          <IconChevronLeft width="17" height="17" fill="var(--color-font)" />
        }
        superNextIcon={
          <IconChevronDoubleRight
            width="17"
            height="17"
            fill="var(--color-font)"
          />
        }
        superPrevIcon={
          <IconChevronDoubleLeft
            width="17"
            height="17"
            fill="var(--color-font)"
          />
        }
        {...props}
      />
    </Form.Item>
  );
};

export default DatePickerUi;
