import { createContext, useCallback } from "react";
import { message } from "antd";
import { IconCheck2, IconError } from "../utils/Icons";

export const MessageContext = createContext(null);

const MessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showSuccessMessage = useCallback((content) => {
    messageApi.open({
      type: "success",
      duration: 5,
      content,
      icon: <IconCheck2 width="22" height="22" fill="var(--color-green)" />
    });
  }, [messageApi]);

  const showErrorMessage = useCallback((content) => {
    messageApi.open({
      type: "error",
      duration: 5,
      content,
      icon: <IconError width="22" height="22" fill="var(--color-red)" />
    });
  }, [messageApi]);

  return (
    <MessageContext.Provider value={{ messageApi, showSuccessMessage, showErrorMessage }}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider