import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IconBriefcase,
  IconGem,
  IconLighting,
  IconShield,
} from "../../utils/Icons";

const Advantages = () => {
  const { t } = useTranslation();
  const basePath = "home.advantages.";

  return (
    <div className="advantages">
      <section>
        <div className="content">
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <div className="item">
                <span>
                  <IconBriefcase
                    width="34"
                    height="34"
                    fill="var(--color-main)"
                  />
                </span>
                <h4>{t(`${basePath}item1.title`)}</h4>
                <p>{t(`${basePath}item1.desc`)}</p>
              </div>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <div className="item">
                <span>
                  <IconGem width="34" height="34" fill="var(--color-main)" />
                </span>
                <h4>{t(`${basePath}item2.title`)}</h4>
                <p>{t(`${basePath}item2.desc`)}</p>
              </div>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <div className="item">
                <span>
                  <IconLighting
                    width="34"
                    height="34"
                    fill="var(--color-main)"
                  />
                </span>
                <h4>{t(`${basePath}item3.title`)}</h4>
                <p>{t(`${basePath}item3.desc`)}</p>
              </div>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <div className="item">
                <span>
                  <IconShield width="34" height="34" fill="var(--color-main)" />
                </span>
                <h4>{t(`${basePath}item4.title`)}</h4>
                <p>{t(`${basePath}item4.desc`)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default Advantages;
