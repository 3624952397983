import React from "react";

const IconCamera = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M8.99903 2C8.73403 2 8.49905 2.09401 8.31105 2.28101L6.59302 4C6.30602 4 5.99903 4 5.99903 4C3.79003 4 1.99902 5.791 1.99902 8V17C1.99902 19.209 3.79003 21 5.99903 21H17.999C20.2081 21 21.9991 19.209 21.9991 17V8C21.9991 5.791 20.2081 4 17.999 4H17.437L15.718 2.28101C15.53 2.09401 15.264 2 14.999 2H8.99903ZM9.43702 4H14.593L16.3111 5.71899C16.4991 5.90599 16.734 6 16.999 6H17.999C19.104 6 19.9991 6.895 19.9991 8V17C19.9991 18.105 19.104 19 17.999 19H5.99903C4.89403 19 3.99903 18.105 3.99903 17V8C3.99903 6.895 4.89403 6 5.99903 6H6.99903C7.26403 6 7.53003 5.90599 7.71803 5.71899L9.43702 4Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12 7C9.239 7 7 9.239 7 12C7 14.761 9.239 17 12 17C14.761 17 17 14.761 17 12C17 9.239 14.761 7 12 7ZM18 7C17.448 7 17 7.448 17 8C17 8.552 17.448 9 18 9C18.552 9 19 8.552 19 8C19 7.448 18.552 7 18 7ZM12 9C13.657 9 15 10.343 15 12C15 13.657 13.657 15 12 15C10.343 15 9 13.657 9 12C9 10.343 10.343 9 12 9Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCamera;
