import React from "react";

const IconExit = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H13C10.7909 3 9 4.79086 9 7C9 7.55229 9.44771 8 10 8C10.5523 8 11 7.55229 11 7C11 5.89543 11.8954 5 13 5H17C18.1046 5 19 5.89543 19 7V17C19 18.1046 18.1046 19 17 19H13C11.8954 19 11 18.1046 11 17C11 16.4477 10.5523 16 10 16C9.44771 16 9 16.4477 9 17C9 19.2091 10.7909 21 13 21H17Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 11C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H5.39818L7.41418 14.9911L6.00879 16.4141L2.2973 12.7485C2.10859 12.5621 2.00166 12.3084 2.00002 12.0431C1.99838 11.7779 2.10217 11.5229 2.28856 11.3342L5.99131 7.58569L7.41418 8.99119L5.4299 11H14Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconExit;
