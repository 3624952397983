import React from "react";

const IconWallet = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M2 7V16.75C2 18.5449 3.4551 20 5.25 20H18.75C20.5449 20 22 18.5449 22 16.75V9C22 8.4477 21.5523 8 21 8H5C4.44771 8 4 7.55228 4 7V7H2ZM5 10H20V16.75C20 17.4404 19.4403 18 18.75 18H5.25C4.5596 18 4 17.4404 4 16.75L4.0058 9.8241C4.0058 9.8241 4.3638 10.0031 5 10ZM17 13C16.4477 13 16 13.4477 16 14C16 14.5523 16.4477 15 17 15C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C4.44772 6 4 6.44772 4 7H2C2 5.34315 3.34315 4 5 4H19C19.5523 4 20 4.44772 20 5V8H18V6H5Z"
        fill={fill}
        fillOpacity={0.4}
      />
    </svg>
  );
};

export default IconWallet;
