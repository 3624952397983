import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Drawer, Form, Select, Slider } from "antd";

const { Option } = Select;

const Filters = ({ toggleDrawer, drawerVisible, handleFilterChange }) => {
  const { t } = useTranslation();
  const basePath = "girlsList.filter.";

  return (
    <Drawer
      title={t(`${basePath}title`)}
      placement="right"
      closable={true}
      onClose={toggleDrawer}
      open={drawerVisible}
    >
      <Form layout="vertical" onValuesChange={handleFilterChange}>
        <Form.Item label={t(`${basePath}age`)} name="ageRange">
          <Slider
            range
            min={18}
            max={50}
            defaultValue={[18, 50]}
            tooltip={{ open: undefined }}
            onChange={(value) => handleFilterChange({ ageRange: value })}
          />
        </Form.Item>

        <Form.Item label={t(`${basePath}country.label`)} name="country">
          <Select placeholder={t(`${basePath}country.placeholder`)} allowClear>
            <Option value="Spain">Spain</Option>
            <Option value="USA">USA</Option>
            <Option value="Canada">Canada</Option>
          </Select>
        </Form.Item>
        <Form.Item name="verified" valuePropName="checked">
          <Checkbox>{t(`${basePath}verified`)}</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Filters;
