import React from "react";

const IconHeartSlash = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M21.339,7.128a8.757,8.757,0,0,1,.225,4.485c-1.229,6.356-8.927,9.921-9.254,10.07a.753.753,0,0,1-.623,0,22.6,22.6,0,0,1-3.259-1.929.75.75,0,1,1,.863-1.226A22.842,22.842,0,0,0,12,20.165c1.406-.717,7.141-3.913,8.093-8.837a7.259,7.259,0,0,0-.173-3.715.75.75,0,1,1,1.42-.485Zm.191-3.6-18,18a.75.75,0,0,1-1.06-1.06l3.142-3.142a11.442,11.442,0,0,1-3.179-5.715A7.861,7.861,0,0,1,3.713,5.19a5.4,5.4,0,0,1,4.3-1.94h.018A4.716,4.716,0,0,1,12,5.394,4.715,4.715,0,0,1,15.963,3.25h.018a5.715,5.715,0,0,1,2.929.78l1.56-1.56a.75.75,0,0,1,1.06,1.06ZM6.671,16.268,17.8,5.138a4.433,4.433,0,0,0-1.828-.388h-.012A3.656,3.656,0,0,0,12.71,7.23a.754.754,0,0,1-.71.509h0a.753.753,0,0,1-.709-.512A3.656,3.656,0,0,0,8.033,4.75H8.021A3.9,3.9,0,0,0,4.872,6.142a6.369,6.369,0,0,0-.967,5.185A9.98,9.98,0,0,0,6.671,16.268Z"
      />
    </svg>
  );
};

export default IconHeartSlash;
