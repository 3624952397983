import React from "react";

const IconEye = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12 3.9978C9.03715 3.9978 6.54091 5.45179 4.49024 7.84179C3.78833 8.65879 3.17453 9.5318 2.67488 10.4038C2.37349 10.9318 2.18925 11.3398 2.08111 11.5918C1.97296 11.8428 1.97296 12.1528 2.08111 12.4038C2.18925 12.6558 2.37349 13.0638 2.67488 13.5918C3.17453 14.4638 3.78833 15.3368 4.49024 16.1538C6.54091 18.5438 9.03715 19.9978 12 19.9978C14.9629 19.9978 17.4591 18.5438 19.5098 16.1538C20.2107 15.3368 20.8255 14.4638 21.3241 13.5918C21.6265 13.0638 21.8108 12.6558 21.9189 12.4038C22.027 12.1528 22.027 11.8428 21.9189 11.5918C21.8108 11.3398 21.6265 10.9318 21.3241 10.4038C20.8255 9.5318 20.2107 8.65879 19.5098 7.84179C17.4591 5.45179 14.9629 3.9978 12 3.9978ZM12 5.9978C14.294 5.9978 16.3036 7.1688 18.0078 9.1538C18.6206 9.8678 19.1323 10.6358 19.5719 11.4038C19.7271 11.6748 19.7941 11.8148 19.8852 11.9978C19.7941 12.1808 19.7271 12.3208 19.5719 12.5918C19.1323 13.3598 18.6206 14.1278 18.0078 14.8418C16.3036 16.8268 14.294 17.9978 12 17.9978C9.70602 17.9978 7.69641 16.8268 5.99219 14.8418C5.3794 14.1278 4.86773 13.3598 4.42716 12.5918C4.27296 12.3208 4.20587 12.1808 4.11475 11.9978C4.20587 11.8148 4.27296 11.6748 4.42716 11.4038C4.86773 10.6358 5.3794 9.8678 5.99219 9.1538C7.69641 7.1688 9.70602 5.9978 12 5.9978Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12 8C9.791 8 8 9.791 8 12C8 14.209 9.791 16 12 16C14.209 16 16 14.209 16 12C16 11.448 15.552 11 15 11C14.448 11 14 11.448 14 12C14 13.105 13.104 14 12 14C10.895 14 10 13.105 10 12C10 10.895 10.895 10 12 10C12.245 10 12.496 10.041 12.719 10.125C13.235 10.32 13.805 10.048 14 9.531C14.195 9.015 13.923 8.445 13.406 8.25C12.958 8.081 12.487 8 12 8Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconEye;
