/* eslint-disable no-lone-blocks */
export function Reducer(state, { type, payload }) {
  switch (type) {
    case "setHideSlide": return {
      ...state,
      hideSlide: !state.hideSlide,
    };
    case "setIsWalletConnect": return {
      ...state,
      isWalletConnect: payload,
    };
    case "setToken": return {
      ...state,
      token: payload || null,
    };
    case "setEmail": return {
      ...state,
      email: payload || null,
    };
    case "setGirl": return {
      ...state,
      girl: !state.girl || null,
    };
    case "setGirlList": return {
      ...state,
      girl_list: payload || null,
    };
    case "setUserInfo": return {
      ...state,
      user_info: payload || null,
    };
    case "setEmailGirl": return {
      ...state,
      emailGirl: payload || null,
    };
    case "setLoading": return {
      ...state,
      loading: !state.loading || null,
    };
    case "setModalInfo": return {
      ...state,
      isModalInfo: payload || null,
    };
    default:
      return state;
  }
}
