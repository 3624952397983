import React from "react";

const IconKey = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M13.094 2.00495C12.796 1.97595 12.493 2.07494 12.281 2.28594L8.4075 6.18295C7.5216 7.06895 7.1767 8.52595 7.5625 9.81695C7.7351 10.395 7.531 11.0679 7.1875 11.4109L2.2812 16.2859C2.0937 16.4739 2 16.74 2 17.005V18.005C2 20.214 3.7909 22.005 6 22.005H7C7.2652 22.005 7.5312 21.911 7.7188 21.724L12.594 16.817C12.937 16.474 13.654 16.274 14.188 16.442C15.422 16.832 16.942 16.458 17.828 15.572L21.719 11.7239C21.93 11.5119 22.029 11.2089 22 10.9109C21.538 6.17094 17.834 2.46695 13.094 2.00495ZM13.367 4.04795C16.801 4.56995 19.43 7.20995 19.952 10.6439L16.418 14.1629C16.066 14.5149 15.368 14.7449 14.777 14.5549C13.546 14.1589 12.067 14.5319 11.188 15.4109L6.6125 19.9909L6 20.005C4.8954 20.005 4 19.11 4 18.005L4.0079 17.403L8.5938 12.817C9.4729 11.938 9.8578 10.423 9.469 9.21995C9.2855 8.65295 9.5082 7.90495 9.8599 7.55395L13.367 4.04795Z"
        fill={fill}
      />
      <circle cx="15" cy="9" r="1" fill={fill} fillOpacity={0.4} />
    </svg>
  );
};

export default IconKey;
