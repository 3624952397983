import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Space, Table } from "antd";
import { IconSearch } from "../../../utils/Icons";

const TableUi = ({ data, columns }) => {
  const { t } = useTranslation();
  const basePath = "user.table.";

  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={t(`${basePath}placeholder`)}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            {t(`${basePath}btnSearch`)}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t(`${basePath}btnReset`)}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t(`${basePath}filter`)}
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            {t(`${basePath}close`)}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <IconSearch
        width="16"
        height="16"
        fill={filtered ? "var(--color-main)" : "var(--color-font)"}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const modifiedColumns = columns.map((col) => ({
    ...col,
    ...(col.searchable ? getColumnSearchProps(col.dataIndex) : {}),
  }));
  // const handleProfileCreation = (e) => {
    
  //   setEmailGirl(e.target.dataset.email);
  //   navigate("/userView");
  // };
  return (
    <Table
    
      columns={modifiedColumns}
      dataSource={data}
      pagination={{
        showSizeChanger: false,
        position: ["bottomCenter"],
      }}
      scroll={{ x: 100 }}
    />
  );
};

export default TableUi;
