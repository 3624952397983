import React from "react";

const IconImage = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7C5 5.89543 5.89543 5 7 5H17C18.1046 5 19 5.89543 19 7V13.1809C20.1309 14.1871 20.9335 15.4867 20.9961 15.5893C20.9987 15.5599 21 15.5301 21 15.5V7C21 4.79086 19.2091 3 17 3H7C4.79086 3 3 4.79086 3 7V15.5C3 15.5 3.75767 13.3785 5 11.5595V7Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9ZM3 15.5C3 15.5 5.32136 9 8.5 9C11.5 9 13.2468 13.9659 13.2468 13.9659C13.2468 13.9659 14.7185 12 16.5 12C18.8375 12 21 15.5957 21 15.5957V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V15.5ZM5 16.1406C5.74232 14.1162 7.21489 11 8.5 11C9.60995 11 11.0021 13.5332 11.9564 16.0216C12.1497 16.5255 12.3455 17 13 17C13.5565 17 13.75 16.6797 13.9574 16.3364C13.9939 16.2759 14.0309 16.2148 14.0704 16.1545C14.6863 15.2136 15.7615 14 16.5 14C17.3017 14 18.5398 15.5143 19 16.2934V17C19 18.1046 18.1046 19 17 19H7C5.8955 19 5.00011 18.1047 5 17.0002V17V16.9999C5 16.9913 4.99989 16.4306 5 16.1406Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconImage;
