import React from "react";

const IconChevronDoubleLeft = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M17.9595 5.82601C17.9097 5.57638 17.7821 5.32375 17.5539 5.17097C17.0985 4.86542 16.4567 4.99421 16.1528 5.45254L12.1667 11.4437C11.9444 11.7782 11.9444 12.2316 12.1667 12.5661L16.1528 18.5572C16.4567 19.0156 17.0985 19.1443 17.5539 18.8388C18.0103 18.5332 18.1389 17.8922 17.8349 17.4349L14.2225 12.0049L17.8349 6.57491C17.9864 6.34624 18.0093 6.07664 17.9595 5.82601Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M11.9595 5.82113C11.9097 5.5715 11.7821 5.31886 11.5539 5.16609C11.0985 4.86054 10.4567 4.98933 10.1528 5.44766L6.16667 11.4388C5.94444 11.7733 5.94444 12.2267 6.16667 12.5612L10.1528 18.5523C10.4567 19.0107 11.0985 19.1395 11.5539 18.8339C12.0103 18.5284 12.1389 17.8873 11.8349 17.43L8.22251 12L11.8349 6.57002C11.9864 6.34136 12.0093 6.07176 11.9595 5.82113Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChevronDoubleLeft;
