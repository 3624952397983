import React from "react";
import { Col, Row, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconGeo, IconMail } from "../utils/Icons";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <footer>
      <section>
        <Row>
          <Col xl={6} lg={6} md={8} sm={24} xs={24}>
            <div className="company">
              <div className="logo">
                <Link to="/" className="logo">
                  <img
                    size="lg"
                    src={require("../assets/img/logo.svg").default}
                    alt="Luxurgirl"
                  />
                  <span>Luxurgirl</span>
                </Link>
              </div>
              <p>{t(`${basePath}desc`)}</p>
            </div>
          </Col>
          <Col xl={6} lg={6} md={8} sm={12} xs={12}>
            <div className="links">
              <span className="subtitle">{t(`${basePath}category.title`)}</span>
              <ul>
                <li>
                  <Link to={"/"}>{t(`${basePath}category.item1`)}</Link>
                </li>
                <li>
                  <Link to={"/"}>{t(`${basePath}category.item2`)}</Link>
                </li>
                <li>
                  <Link to={"/"}>{t(`${basePath}category.item3`)}</Link>
                </li>
                <li>
                  <Link to={"/"}>{t(`${basePath}category.item4`)}</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={6} lg={6} md={8} sm={12} xs={12}>
            <div className="links">
              <span className="subtitle">{t(`${basePath}account.title`)}</span>
              <ul>
                <li>
                  <Link to={"/"}>{t(`${basePath}account.item1`)}</Link>
                </li>
                <li>
                  <Link to={"/"}>{t(`${basePath}account.item2`)}</Link>
                </li>
                <li>
                  <Link to={"/"}>{t(`${basePath}account.item3`)}</Link>
                </li>
                <li>
                  <Link to={"/"}>{t(`${basePath}account.item4`)}</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={6} lg={6} md={24} sm={24} xs={24}>
            <div className="links">
              <span className="subtitle">{t(`${basePath}contact.title`)}</span>
              <div className="contact-link">
                <span>
                  <IconGeo width="24" height="24" fill="var(--color-main)" />
                </span>
                193 Fleet St, London EC4A 2AH, United Kingdom
              </div>
              <div className="contact-link">
                <span>
                  <IconMail width="24" height="24" fill="var(--color-main)" />
                </span>
                example@email.com
              </div>
              <div className="social">
                <span>{t(`${basePath}contact.social`)}</span>
                <div className="item">
                  <Tooltip title="Telegram">
                    <Link to={"/"}>
                      <img
                        src={
                          require("../assets/img/icons/telegram.svg").default
                        }
                        alt=""
                      />
                    </Link>
                  </Tooltip>
                </div>
                <div className="item">
                  <Tooltip title="Facebook">
                    <Link to={"/"}>
                      <img
                        src={
                          require("../assets/img/icons/facebook.svg").default
                        }
                        alt=""
                      />
                    </Link>
                  </Tooltip>
                </div>
                <div className="item">
                  <Tooltip title="X">
                    <Link to={"/"}>
                      <img
                        src={require("../assets/img/icons/x.svg").default}
                        alt=""
                      />
                    </Link>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </footer>
  );
};

export default Footer;
