import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserTypeContext } from "../contexts/UserTypeContext";

import Home from "../pages/Home";
import Profile from "../pages/User/Profile";
import Chats from "../pages/User/Chats";
import Friends from "../pages/User/Friends";
import Media from "../pages/User/Media";
import Settings from "../pages/User/Settings";
import NotFound from "../pages/NotFound";
import PreviewUser from "../pages/User/PreviewUser";
import { PreloaderUi } from "../components/ui";
import Girls from "../pages/User/Girls";

function Routess() {
  const { token, loading } = useContext(UserTypeContext);

  

  return (
    <>
      <BrowserRouter>
        {/* {loading ? <PreloaderUi /> : null } */}
        <Routes>
          <Route path="/" element={<Home />} />
          {
            token ?
              <>
                <Route path="/user" element={<Profile />} />
                <Route path="/chats" element={<Chats />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="/media" element={<Media />} />
                <Route path="/girls" element={<Girls />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/userView" element={<PreviewUser />} />
              </>
              : <Route path="/*" element={<NotFound />} />
          }
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routess;
