import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Image, Row, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { PreloaderUi } from "./ui";
import UploadCoverModal from "./Modal/UploadCoverModal";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { getCurrentAge } from "../utils/getCurrentAge";
import {
  IconArrowClockwise,
  IconArrowReturnLeft,
  IconBadgeCheck,
  IconPencil,
  IconPersonBox,
} from "../utils/Icons";
import {
  getUserInfo,
  getUserInfoGirl,
  updateUserInfo,
  updateUserInfoGirl,
} from "../utils/Api";
import useMessage from "../hooks/useMessage";
import VerificationModal from "./Modal/VerificationModal";
import InfoModal from "./Modal/InfoModal"; // Import the InfoModal
import { io } from "socket.io-client";

const { Paragraph } = Typography;
const socket = io.connect("https://girlluxury.xyz/");


const UserLayout = ({
  userInfo,
  setUserInfoState,
  children,
  className,
  type,
}) => {
  const { t } = useTranslation();
  const basePath = "user.layout.";

  const { setModalInfo,isModalInfo,isWalletConnect,user_info, token, setUserInfo, girl, email } =
    useContext(UserTypeContext);

  const navigate = useNavigate();

  const [isLoading] = useState(false);
  const [isCoverModalOpen, setIsCoverModalOpen] = useState(false);
  const [isVerifModalOpen, setIsVerifiModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false); // State to control InfoModal visibility

  const { showErrorMessage, showSuccessMessage } = useMessage();

  const fetchUserInfo = useCallback(async () => {
    const fetchUser = girl ? getUserInfoGirl : getUserInfo;
    const res = await fetchUser(email, token);
    setUserInfo(res);
  }, [girl, email, token, setUserInfo]);

  useEffect(() => {
    fetchUserInfo();
    if (!token){
      navigate("/")
    }else if (
      token &&
      (!user_info.profile || !user_info.photo_title || !user_info.photo_cover || (!isWalletConnect && !user_info.title))
    ) {
      navigate("/settings");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    socket.on("receive_message", fetchUserInfo);

    return () => {
      socket.off("receive_message", fetchUserInfo);
    };
  }, []);

  const [editableDescription, setEditableDescription] = useState(
    userInfo?.discripton || t(`${basePath}description.title`)
  );

  const coverImage = useMemo(() => {
    return (
      userInfo?.photo_cover || require("../assets/img/placeholde-large.png")
    );
  }, [userInfo?.photo_cover]);

  const handleDescriptionChange = async (newDescription) => {
    try {
      setEditableDescription(newDescription);
      const updatedUserInfo = { ...userInfo, discripton: newDescription };

      const updateUser = girl ? updateUserInfoGirl : updateUserInfo;
      await updateUser(updatedUserInfo, token);

      showSuccessMessage(t(`${basePath}description.success`));
      await fetchUserInfo();
    } catch (error) {
      showErrorMessage(t(`${basePath}description.error`));
    }
  };

  return (
    <>
      {isLoading ? (
        <PreloaderUi />
      ) : (
        <>
          <NavBar type={"large"} />
          <main className="user">
            <SideBar />
            <div className={className}>
              {type !== "noBanner" && (
                <div className={`user-banner ${type}`}>
                  <Row>
                    <Col span={24}>
                      <div
                        className="bg-cover"
                        style={{
                          backgroundImage: `url(${coverImage})`,
                        }}
                      >
                        <Row justify="center">
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="info-user">
                              <div className="image">
                                <img
                                  src={
                                    userInfo.photo_title ||
                                    require("../assets/img/placeholde-small.png")
                                  }
                                  alt="User Avatar"
                                />
                              </div>
                              <div className="content">
                                <h2>
                                  {userInfo.name || t(`${basePath}name`)}
                                  {userInfo.verif === 3 && (
                                    <IconBadgeCheck
                                      width="26"
                                      height="26"
                                      fill="var(--color-blue)"
                                    />
                                  )}
                                </h2>

                                <div className="date">
                                  <span>
                                    Age: {getCurrentAge(userInfo.date_bith)}
                                  </span>
                                  <span>
                                    {userInfo.location?.country || "-"}
                                  </span>
                                </div>
                                <p>
                                  {type !== "view" ? (
                                    <Paragraph
                                      className="text"
                                      editable={{
                                        icon: (
                                          <IconPencil
                                            width="20"
                                            height="20"
                                            fill="var(--color-white)"
                                          />
                                        ),
                                        enterIcon: (
                                          <IconArrowReturnLeft
                                            width="20"
                                            height="20"
                                            fill="var(--color-white)"
                                          />
                                        ),
                                        onChange: handleDescriptionChange,
                                      }}
                                    >
                                      {editableDescription}
                                    </Paragraph>
                                  ) : (
                                    <Paragraph className="text">
                                      {userInfo?.discripton}
                                    </Paragraph>
                                  )}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      {type !== "view" && (
                        <div className="media">
                          <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                              <div className="content">
                                <div className="media-img">
                                  {userInfo.photos &&
                                  userInfo.photos.length > 0 ? (
                                    <Image.PreviewGroup>
                                      {userInfo.photos
                                        .slice(0, 4)
                                        .map((photo, index) => (
                                          <Image
                                            key={index}
                                            width={60}
                                            height={60}
                                            src={photo}
                                            alt={`media-${index}`}
                                          />
                                        ))}
                                    </Image.PreviewGroup>
                                  ) : (
                                    <Link to={`/media`} className="not-media">
                                      <img
                                        src={require("../assets/img/placeholde-small.png")}
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                  <span className="subtitle">
                                    <p>{t(`${basePath}media`)}</p>
                                  </span>
                                </div>

                                {user_info.verif === 3 ? (
                                  <div className="verified">
                                    <IconBadgeCheck
                                      width="20"
                                      height="20"
                                      fill="var(--color-green)"
                                    />
                                    {t(`${basePath}verified`)}
                                  </div>
                                ) : user_info.verif === 2 ? (
                                  <div className="verifprogres">
                                    <IconArrowClockwise
                                      width="16"
                                      height="16"
                                      fill={"#e5a23f"}
                                    />
                                    {t(`${basePath}proccesing`)}
                                  </div>
                                ) : (
                                  <div className="verif">
                                    <Button
                                      onClick={() => setIsVerifiModalOpen(true)}
                                    >
                                      <IconPersonBox
                                        width="20"
                                        height="20"
                                        fill="var(--color-font)"
                                      />
                                      {t(`${basePath}btnVerified`)}
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              )}

              <Row justify="center">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  {children}
                </Col>
              </Row>
            </div>

            <UploadCoverModal
              setUserInfoo={setUserInfoState}
              isModalOpen={isCoverModalOpen}
              handleCancel={() => setIsCoverModalOpen(false)}
            />

            <VerificationModal
              isModalOpen={isVerifModalOpen}
              fetchUserInfo={fetchUserInfo}
              handleCancel={() => setIsVerifiModalOpen(false)}
            />

            {/* Display InfoModal if it hasn't been shown before */}
            <InfoModal
              isModalOpen={isModalInfo}
              handleCancel={() => setModalInfo(false)}
            />
          </main>
        </>
      )}
    </>
  );
};

export default UserLayout;
