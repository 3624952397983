import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Button } from "antd";
import TinderCard from "react-tinder-card";
import { useTranslation } from "react-i18next";
import useMessage from "../../hooks/useMessage";
import { GIRLS_ARRAY } from "../../constants/GirlsArray";
import { getCurrentAge } from "../../utils/getCurrentAge";
import { IconBadgeCheck, IconHeart, IconHeartSlash } from "../../utils/Icons";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import { createChat, getGirlsList, getUserInfo } from "../../utils/Api";
import { useNavigate } from "react-router-dom";

function TinderSwipe() {
  const { t } = useTranslation();
  const basePath = "girlsList.";
  const navigate = useNavigate();

  const {
    setEmailGirl,
    user_info,
    girl,
    girl_list,
    setGirlList,
    token,
    setUserInfo,
    email,
  } = useContext(UserTypeContext);

  const { showErrorMessage, showSuccessMessage } = useMessage();

  const fetchGirlsList = useCallback(() => {
    if (token && !girl) {
      getGirlsList(token).then((res) => {
        setGirlList(
          res
            .filter(
              (e) =>
                user_info.chatRooms.findIndex((i) => i.receiver_id == e.id) ===
                -1
            )
            .sort(() => Math.random() - 0.5)
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, girl]);

  useEffect(() => {
    fetchGirlsList();
  }, [fetchGirlsList]);

  const [currentIndex, setCurrentIndex] = useState(girl_list.length - 1);
  const [, setLastDirection] = useState();

  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(girl_list.length)
        .fill(0)
        .map((i) => React.createRef()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex > 0;

  const swiped = (direction, nameToDelete, index, id) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
    if (direction === "right") handleChatCreation(id);
  };

  const outOfFrame = (name, idx) => {
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < girl_list.length + 1) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  const handleChatCreation = (id) => {
    const existingChat = user_info.chatRooms.find(
      (chat) => chat.receiver_id === id
    );

    if (existingChat) {
      showErrorMessage(t(`${basePath}errorExisting`));
    } else {
      const girl = girl_list.find((el) => el.id === Number(id));

      if (girl) {
        const objGil = { ...girl, photo_cover: null, photos: null };
        const objUser = { ...user_info, photo_cover: null, photos: null };

        createChat(
          `${Number(user_info.id) + Number(girl.id)}`,
          user_info.id,
          user_info.name,
          girl.id,
          girl.name,
          "test1",
          objGil,
          objUser,
          token
        )
          .then(() => {
            showSuccessMessage(t(`${basePath}success`));
            getUserInfo(email, token).then((r) => {
              setUserInfo(r);
            });
          })
          .catch(() => {
            showErrorMessage(t(`${basePath}error`));
          });
      }
    }
  };

  // const canGoBack = currentIndex < girl_list.length - 1;

  // const goBack = async () => {
  //   if (!canGoBack) return;
  //   const newIndex = currentIndex + 1;
  //   updateCurrentIndex(newIndex);
  //   await childRefs[newIndex].current.restoreCard();
  // };

  const handleProfileCreation = (e) => {
    setEmailGirl(e.target.dataset.email);
    navigate("/userView");
  };

  console.log(girl_list);

  return (
    <div className="girls-list">
      <div className="wrapper">
        {girl_list.length !== 0 ? (
          girl_list.map((item, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              key={item.name}
              id={item.id}
              onSwipe={(dir) => swiped(dir, item.name, index, item.id)}
              onCardLeftScreen={() => outOfFrame(item.name, index)}
            >
              <div
                style={{ backgroundImage: "url(" + item.photo_title + ")" }}
                className="card"
              >
                <div
                  className="info"
                  onClick={(e) => handleProfileCreation(e)}
                  data-email={item.email}
                >
                  <h2
                    onClick={(e) => handleProfileCreation(e)}
                    data-email={item.email}
                  >
                    {item.name}
                    {item.verif === 3 && (
                      <IconBadgeCheck
                        width="22"
                        height="22"
                        fill="var(--color-blue)"
                      />
                    )}
                  </h2>
                  <div className="date">
                    <span>
                      {t(`${basePath}tinder.age`)}{" "}
                      {getCurrentAge(item.date_bith)}
                    </span>
                    <span>{item.location.country || "-"}</span>
                  </div>
                </div>
              </div>
            </TinderCard>
          ))
        ) : (
          <div className="coming-soon">
            <img
              src={require("../../assets/img/placeholde-girls.png")}
              alt="Coming Soon"
            />
          </div>
        )}
      </div>
      <div className="buttons">
        <Button onClick={() => swipe("left")}>
          <IconHeartSlash width="20" height="20" fill="var(--color-main)" />
          {t(`${basePath}tinder.left`)}
        </Button>
        {/* <Button
          style={{ backgroundColor: !canGoBack && "#c3c4d3" }}
          onClick={() => goBack()}
        >
          Undo swipe!
        </Button> */}
        <Button onClick={() => swipe("right")}>
          {t(`${basePath}tinder.right`)}
          <IconHeart width="20" height="20" fill="var(--color-main)" />
        </Button>
      </div>
      <div className="girls-inner">
        {GIRLS_ARRAY.slice(0, 7).map((item, index) => (
          <div className="element" key={index}>
            <img src={item.img} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TinderSwipe;
