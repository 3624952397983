import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";

/* Components Home */
import Banner from "../components/Home/Banner";
import CTA from "../components/Home/CTA";
import Keep from "../components/Home/Keep";
import About from "../components/Home/About";
import Advantages from "../components/Home/Advantages";
import Testimonials from "../components/Home/Testimonials";
import Footer from "../components/Footer";
import List from "../components/Girls/List";
import { GIRLS_ARRAY } from "../constants/GirlsArray";
import { Col, Row } from "antd";
import { CardUi } from "../components/ui";
import { getGirlListFree } from "../utils/Api";

function Home() {
  const [girl_list, setGirlList] = useState([]);

  useEffect(() => {
    getGirlListFree().then(r=>{
      setGirlList(r.reverse().slice(0,8))
    })
  });
  return (
    <>
      <NavBar />
      <main className="home">
        <Banner />

        <div className="products">
          <section>
            <h2>
              <span>Meet our new</span>
              Lovely Members
            </h2>

            <Row gutter={[16, 16]}>
          {girl_list.map((girl) => (
            <Col xl={6} lg={6} md={12} sm={24} xs={24} key={girl.id}>
              <CardUi
                // email={girl.email}
                id={girl.id}
                name={girl.name}
                age={girl.date_bith}
                city={girl.location.country}
                img={girl.photo_title}
                description={girl.discripton}
                verification={girl.verif}
              />
            </Col>
          ))}
        </Row>
          </section>
        </div>

        <CTA />
        <Advantages />
        <About />
        <Testimonials />
        <Keep />
        <Footer />
      </main>
    </>
  );
}

export default Home;
