import React from "react";

const IconChevronRight = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M9.04169 18.1735C9.09169 18.4235 9.21768 18.6755 9.44668 18.8285C9.90468 19.1345 10.5427 19.0065 10.8477 18.5475L14.8327 12.5565C15.0557 12.2215 15.0557 11.7685 14.8327 11.4335L10.8477 5.44247C10.5427 4.98347 9.90468 4.85548 9.44668 5.16148C8.98868 5.46748 8.86069 6.10647 9.16669 6.56547L12.7777 11.9955L9.16669 17.4245C9.01369 17.6535 8.99169 17.9225 9.04169 18.1735Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.832 8.44516L14.832 11.4452C15.056 11.7811 15.056 12.2187 14.832 12.5546L12.832 15.5546L12.832 8.44516Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChevronRight;
