import React, { useContext, useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { useNavigate } from "react-router-dom";
import { PreloaderUi } from "../components/ui";

function NotFound() {
  const { t } = useTranslation();
  const { token, user_info } = useContext(UserTypeContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) navigate("/");
    if (token && !user_info.profile) {
      navigate("/settings");
    } else if (token && user_info.profile) {
      navigate("/user");
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user_info, navigate]);

  if (loading) {
    return <PreloaderUi />;
  }

  return (
    <div className="not-page">
      <div className="content">
        <img src={require("../assets/img/404.svg").default} alt="" />
        <div className="text">
          <h1>{t(`notFound.title`)}</h1>
          <Button href="/" type="text">
            {t(`notFound.btn`)}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
