import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Avatar, Space, Tooltip } from "antd";
import { TableUi } from "../../components/ui";
import { IconPerson } from "../../utils/Icons";
import UserLayout from "../../components/UserLayout";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import { getCurrentAge } from "../../utils/getCurrentAge";

const Friends = () => {
  const { t } = useTranslation();
  const basePath = "user.friends.";

  const { user_info, setEmailGirl } = useContext(UserTypeContext);

  const navigate = useNavigate();

  const handleProfileView = (record) => {
    setEmailGirl(record.info.email);
    navigate("/userView");
  };

  const columns = [
    {
      title: t(`${basePath}table.user`),
      dataIndex: "info",
      key: "info.name",
      width: "30%",
      render: (info) => (
        <Space>
          <Avatar src={info?.photo_title || null} />
          {info?.name || "Unknown"}
        </Space>
      ),
      searchable: true,
    },
    {
      title: t(`${basePath}table.age`),
      dataIndex: "info",
      key: "info.date_bith",
      width: "20%",
      render: (info) => getCurrentAge(info?.date_bith) || "N/A",
    },
    {
      title: t(`${basePath}table.country`),
      dataIndex: "info",
      key: "info.location.country",
      render: (info) => info?.location?.country || "Unknown",
    },
    {
      title: t(`${basePath}table.status.title`),
      dataIndex: "info",
      key: "info.online",
      render: (info) => (
        <Space>
          <span className={`status ${info?.online}`}>
            {info?.online
              ? t(`${basePath}table.status.online`)
              : t(`${basePath}table.status.offline`)}
          </span>
        </Space>
      ),
    },
    {
      title: t(`${basePath}table.actions.title`),
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="actions">
          <Tooltip title={t(`${basePath}table.actions.profile`)}>
            <span onClick={() => handleProfileView(record)} className="prof">
              <IconPerson width="20" height="20" fill="var(--color-font)" />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <UserLayout userInfo={user_info} className="page friends">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <h4 className="title-block">{t(`${basePath}title`)}</h4>
          <TableUi data={user_info.chatRooms} columns={columns} />
        </Col>
      </Row>
    </UserLayout>
  );
};

export default Friends;
