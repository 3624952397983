import React, { useCallback, useContext, useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  IconCamera,
  IconChat,
  IconExit,
  IconGear,
  IconGem,
  IconPerson,
} from "../utils/Icons";
import WalletConnectUi from "./ui/elements/WalletConnectUi";
import { UserTypeContext } from "../contexts/UserTypeContext";

const SideBar = ({ isActive }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "sideBar.";

  const { hideSlide,user_info } =
  useContext(UserTypeContext);

  const [unread, setUnread] = useState(0);


  const location = useLocation();

  useEffect(() => {
    let k=0
    user_info.chatRooms.find(el=>{
      if(el.unreadMessage > 0) k++
    })
    setUnread(k)
  },[user_info]);

  const exit = useCallback(() => {
    localStorage.removeItem("email");
    localStorage.removeItem("pas");
    localStorage.removeItem("checkMe");
    window.location.reload();
  }, []);

  const items = [
    {
      label: <Link to="/user">{t(`${basePath}menu.profil`)}</Link>,
      path: "/user",
      key: "1",
      icon: <IconPerson width="22" height="22" fill="var(--color-main)" />,
    },
    {
      label: <Link to="/chats">{t(`${basePath}menu.chats`)}:{unread}</Link>,
      path: "/chats",
      key: "2",
      icon: <IconChat width="22" height="22" fill="var(--color-main)" />,
    },
    {
      label: <Link to="/media">{t(`${basePath}menu.media`)}</Link>,
      path: "/media",
      key: "4",
      icon: <IconCamera width="22" height="22" fill="var(--color-main)" />,
    },
    {
      label: <Link to="/girls">{t(`${basePath}menu.girls`)}</Link>,
      path: "/girls",
      key: "5",
      icon: <IconGem width="22" height="22" fill="var(--color-main)" />,
    },
    {
      type: "divider",
    },
    {
      label: <Link to="/settings">{t(`${basePath}menu.setting`)}</Link>,
      path: "/settings",
      key: "6",
      icon: <IconGear width="22" height="22" fill="var(--color-main)" />,
    },
  ];

  const selectedKey = items.find((item) => item.path === location.pathname);
  const resultItems = items.reduce(
    (acc, item) => (item ? [...acc, item] : acc),
    []
  );

  return (
    
    <>
    {hideSlide ?
      <div className={`side-bar ${isActive ? "active" : ""}`}>
        <Menu
          className="side-bar-menu"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedKey?.key]}
          mode="inline"
          theme="dark"
          inlineCollapsed={false}
          items={resultItems}
        />

        <div className="side-bar-footer">
          <WalletConnectUi />
          <ul>
            <li>
              <Link to="/" onClick={exit}>
                <IconExit width="22" height="22" fill="var(--color-main)" />
                {t(`${basePath}menu.exit`)}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      : null}
    </> 
  );
};

export default SideBar;
