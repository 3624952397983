import React from "react";

const IconHeart = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M12,21.75a.751.751,0,0,1-.311-.067c-.327-.149-8.023-3.718-9.256-10.075A7.846,7.846,0,0,1,3.714,5.192a5.4,5.4,0,0,1,4.3-1.942h.026A4.7,4.7,0,0,1,12,5.392,4.7,4.7,0,0,1,15.982,3.25a5.4,5.4,0,0,1,4.307,1.942,7.847,7.847,0,0,1,1.277,6.416c-1.232,6.357-8.928,9.926-9.255,10.075A.751.751,0,0,1,12,21.75Zm-3.958-17H8.025A3.894,3.894,0,0,0,4.873,6.144a6.355,6.355,0,0,0-.968,5.179c.955,4.924,6.688,8.124,8.1,8.842,1.407-.718,7.14-3.918,8.094-8.842h0a6.357,6.357,0,0,0-.964-5.179A3.9,3.9,0,0,0,15.975,4.75h-.017a3.65,3.65,0,0,0-3.247,2.478.754.754,0,0,1-.71.509h0a.751.751,0,0,1-.709-.512A3.651,3.651,0,0,0,8.042,4.75Z"
      />
    </svg>
  );
};

export default IconHeart;
