import React, { useContext } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { ChatUi } from "../../components/ui";
import UserLayout from "../../components/UserLayout";
import { UserTypeContext } from "../../contexts/UserTypeContext";

const Profile = () => {
  const { t } = useTranslation();
  const basePath = "user.profile.";

  const { user_info,girl } = useContext(UserTypeContext);

  return (
    <>
      <UserLayout userInfo={user_info} className={"page profile"}>
        <Row>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <h4 className="title-block">{t(`${basePath}about.title`)}</h4>
            <div className="about">
              <div className="item">
                <span>{t(`${basePath}about.name`)}</span>
                {user_info.name === null ? "---" : user_info.name}
              </div>
              <div className="item">
                <span>{t(`${basePath}about.date`)}</span>
                {user_info.date_bith === null ? "---" : user_info.date_bith}
              </div>
              <div className="item">
                <span>{t(`${basePath}about.phone`)}</span>
                {user_info.phone === null ? "---" : user_info.phone}
              </div>
              <div className="item">
                <span>{t(`${basePath}about.city`)}</span>
                {user_info.location.country === null
                  ? "---"
                  : user_info.location.country}
              </div>
              <div className="item">
                <span>{t(`${basePath}about.email`)}</span>
                {user_info.email === null ? "---" : user_info.email}
              </div>
            </div>
          </Col>
          <Col xl={16} lg={16} md={24} sm={24} xs={24}>
            <h4 className="title-block">{t(`${basePath}chats`)}</h4>
            <ChatUi girl={girl} userinfo={user_info} size={"small"} />
          </Col>
        </Row>
      </UserLayout>
    </>
  );
};

export default Profile;
