import React from "react";

const IconGlobe = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.019 11C10.0955 9.00275 10.3973 7.24257 10.8274 5.95227C11.0794 5.19616 11.3575 4.65642 11.6177 4.32624C11.7455 4.16408 11.8494 4.07752 11.9186 4.03507C11.9515 4.01483 11.9736 4.00645 11.9847 4.00305C11.9948 3.99998 11.999 4 11.9999 4L12 4L12.0001 4C12.001 4 12.0052 3.99998 12.0153 4.00305C12.0264 4.00645 12.0485 4.01483 12.0814 4.03507C12.1506 4.07752 12.2545 4.16408 12.3823 4.32624C12.6425 4.65642 12.9206 5.19616 13.1726 5.95227C13.6027 7.24257 13.9045 9.00275 13.981 11H10.019ZM8.01766 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H8.01766C8.09487 15.1737 8.42177 17.1555 8.93 18.6802C9.22085 19.5528 9.58923 20.331 10.0469 20.9117C10.4956 21.4811 11.1481 22 12 22C12.8519 22 13.5044 21.4811 13.9531 20.9117C14.4108 20.331 14.7791 19.5528 15.07 18.6802C15.5782 17.1555 15.9051 15.1737 15.9823 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H15.9823C15.9051 8.8263 15.5782 6.84448 15.07 5.31981C14.7791 4.44724 14.4108 3.66896 13.9531 3.08829C13.5044 2.51889 12.8519 2 12 2C11.1481 2 10.4956 2.51889 10.0469 3.08829C9.58923 3.66896 9.22085 4.44724 8.93 5.31981C8.42177 6.84448 8.09487 8.8263 8.01766 11ZM13.981 13C13.9045 14.9972 13.6027 16.7574 13.1726 18.0477C12.9206 18.8038 12.6425 19.3436 12.3823 19.6738C12.2545 19.8359 12.1506 19.9225 12.0814 19.9649C12.0485 19.9852 12.0264 19.9935 12.0153 19.9969C12.0052 20 12.001 20 12.0001 20H12H11.9999C11.999 20 11.9948 20 11.9847 19.9969C11.9736 19.9935 11.9515 19.9852 11.9186 19.9649C11.8494 19.9225 11.7455 19.8359 11.6177 19.6738C11.3575 19.3436 11.0794 18.8038 10.8274 18.0477C10.3973 16.7574 10.0955 14.9972 10.019 13H13.981Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconGlobe;
