import { Form, Select } from "antd";
import React from "react";
import { IconChevronDown } from "../../../utils/Icons";

const SelectUi = ({ name, id, label, icon, errorMess, ...props }) => {
  return (
    <Form.Item name={name} rules={[{ required: true, message: errorMess }]}>
      <label htmlFor={name}>
        {label} {icon}
      </label>
      <Select
        id={id}
        {...props}
        suffixIcon={
          <IconChevronDown width="16" height="16" fill="var(--color-font)" />
        }
      />
    </Form.Item>
  );
};

export default SelectUi;
