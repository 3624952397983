import React from "react";

const IconChat = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12 2.99841C6.50204 2.99841 2.00003 6.99941 2.00003 11.9984C2.00003 13.6214 2.48403 15.1934 3.37503 16.5604C3.51703 16.7794 3.64105 17.2744 3.56205 17.7484C3.48205 18.2384 3.22603 18.7174 2.71903 18.9984C2.56103 19.0864 2.00503 19.3304 2.00003 19.9984C1.99503 20.6664 2.57103 20.9954 3.00003 20.9984C4.01504 21.0064 10.253 20.9984 12 20.9984C17.4981 20.9984 22.0001 16.9974 22.0001 11.9984C22.0001 6.99941 17.4981 2.99841 12 2.99841ZM12 4.99841C16.4431 4.99841 20.0001 8.16041 20.0001 11.9984C20.0001 15.8364 16.4431 18.9984 12 18.9984C10.672 18.9984 7.62404 18.9984 5.25004 18.9984C5.70904 18.0454 5.67504 16.5324 5.03104 15.4674C4.38504 14.3974 4.00004 13.2274 4.00004 11.9984C4.00004 8.16041 7.55704 4.99841 12 4.99841Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChat;
