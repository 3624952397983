export const TRANSLATIONS_ES = {
    navBar: {
        lang: {
            'en-US': "Inglés",
            'de-DE': "Alemán",
            'es-ES': "Español",
            'ru-RU': "Ruso",
        },
        profile: "Perfil",
        exit: 'Cerrar sesión',
        logIn: "Conectarse",
        menu: {
            profil: "Perfil",
            chats: "Chats",
            setting: "Ajustes",
            exit: 'Cerrar sesión',
        }
    },

    sideBar: {
        menu: {
            profil: "Perfil",
            chats: "Chats",
            friend: "Amigos",
            media: "Media",
            setting: "Ajustes",
            girls: "Girls",
            exit: 'Cerrar sesión',
        },
    },

    auth: {
        title: "Autorización",
        loginTab: 'Iniciar sesión',
        signUpTab: 'Inscripción',
        form: {
            email: {
                label: "Inicio de sesión",
                placeholder: "Introduzca su Inicio de sesión",
            },
            pass: {
                label: "Contraseña",
                placeholder: "Introduzca su contraseña",
            },
            passConfirm: {
                label: "Confirmar contraseña",
                placeholder: "Introduzca su contraseña",
            },
            type: {
                label: "Tipo de cuenta",
                placeholder: "Seleccione un tipo de cuenta",
                item: {
                    user: 'Usuario',
                    girl: 'Participante',
                }
            },
            remember: "Recuérdame",
            btnLog: 'Conectarse',
            btnSig: 'Inscríbete'
        },
        success: {
            registrationSuccess: "Se ha registrado correctamente. Inicie sesión en su cuenta.",
        },
        errors: {
            userNotFound: "Usuario no encontrado.",
            serverError: "Error del servidor",
            missingFields: 'Rellene todos los campos.',
            passwordMismatch: "Las contraseñas no coinciden",
            emailInUse: "¡Error! El correo electrónico ya está en uso.",
        },
    },

    home: {
        banner: {
            title: "¡Conoce a chicas encantadoras y empieza a ligar ahora mismo!",
            desc: 'Crea un perfil y descubre el mundo de las citas con chicas interesantes y activas que esperan tu mensaje.',
            btn: "Póngase en marcha",
        },

        cta: {
            item1: {
                title: "¡Conoce a las chicas!",
                desc: "Crea un perfil y empieza a chatear con chicas que están esperando tu mensaje.",
            },
            item2: {
                title: "Encuentre nuevos conocidos",
                desc: "Crea un perfil y empieza a recibir mensajes de chicos.",
            },
            btn: "Empezar ahora",
        },

        advantages: {
            item1: {
                title: "Interfaz fácil de usar",
                desc: "Personaliza fácilmente tu perfil y encuentra nuevos conocidos gracias a su diseño intuitivo.",
            },
            item2: {
                title: "Chicas de verdad",
                desc: "Todos los perfiles están verificados, por lo que sólo chatearás con personas reales.",
            },
            item3: {
                title: "Inicio rápido",
                desc: "Sólo tardarás un par de minutos en registrarte y podrás empezar a chatear.",
            },
            item4: {
                title: "Protección de datos",
                desc: "Nos preocupamos por su privacidad manteniendo a salvo su información personal.",
            },
        },

        about: {
            title: "Acerca de la empresa",
            subtitle: "Encuentre a su compañero de vida",
            desc: "Somos una plataforma moderna que une a personas para la comunicación, la amistad y las citas románticas. Nuestro objetivo es hacer que el proceso de comunicación sea lo más cómodo y divertido posible. Todas las chicas que crean perfiles han sido verificadas, lo que garantiza la honestidad y autenticidad de la comunicación. Estamos desarrollando constantemente nuestro servicio, añadiendo nuevas funciones para mejorar tu experiencia de chat. ¡Únete a nosotros y encuentra a tu compañera perfecta ahora mismo!",
            stat: {
                user: "Usuario",
                chats: "Chats",
                years: "Años de experiencia",
            },
            btn: "Póngase en marcha",
            info: "Más de 10 millones de clientes de confianza en todo el mundo",
        },

        testimonials: {
            title: "Testimonios",
        },

        keep: {
            title: "Manténgase en contacto con la Comunidad",
            desc: "¡Suscríbase ahora a Luxurgirl y encuentre a su compañero de vida!",
            btn: "Póngase en marcha",
        },
    },

    user: {
        layout: {
            name: 'Su nombre',
            media: "Media",
            description: {
                title: "algo sobre mi...",
                error: "Has actualizado correctamente tu biografía.",
                success: "No se ha podido actualizar la biografía. Por favor, inténtelo de nuevo.",
            },
            btnVerified: "Verificar perfil",
            verified: "Verificado",
            proccesing: "En curso",
        },

        profile: {
            about: {
                title: "Acerca de",
                name: "Nombre",
                date: "Fecha de nacimiento",
                phone: "Teléfono",
                city: "Ciudad",
                email: "Email",
            },
            chats: "Últimos chats",
        },

        chat: {
            title: "Chats",
            recent: "Recientes",
            placeholder: {
                title: "Bienvenido,",
                desc: "Seleccione un chat para iniciar la mensajería.",
            },
            profile: "Perfil",
            input: "Introducir mensaje",
            btn: "Enviar",
            btnFile: "Enviar archivo"
        },

        friends: {
            title: "Amigos",
            table: {
                user: "Usuario",
                age: "Edad",
                country: "País",
                status: {
                    title: "Estado",
                    online: "En línea",
                    offline: "Fuera de línea",
                },
                actions: {
                    title: "Acciones",
                    profile: "Perfil",
                }
            },
        },

        media: {
            title: "Media",
            btnUpload: "Subir fotos",
            btnDelete: "Borrar foto",
            empty: 'Sin fotos',
        },

        settings: {
            alert: "Rellene su perfil",
            wallet: "Billetera Connect",
            personal: {
                title: "Información personal",
                verif: {
                    btn: "Verificar perfil",
                    verified: "Verificado",
                    proccesing: "En curso",
                },
                alertVerif: "Verificar",
                alertWallet: "Conecta tu billetera",
                form: {
                    name: {
                        label: "Nombre",
                        placeholder: "Introduzca su nombre",
                        error: "Por favor, introduzca su nombre",
                    },
                    email: {
                        label: "Email",
                    },
                    birthday: {
                        label: "Cumpleaños",
                        error: "Por favor, introduzca su fecha de nacimiento",
                    },
                    phone: {
                        label: "Número de teléfono",
                        placeholder: "Introduzca su número de teléfono",
                        error: "Por favor, introduzca un número de teléfono",
                    },
                    location: {
                        country: {
                            title: "País",
                            placeholder: "Seleccionar país",
                        },
                        state: {
                            title: "Estado",
                            placeholder: "Seleccionar Estado",
                        },
                        city: {
                            title: "Ciudad",
                            placeholder: "Seleccionar ciudad",
                        },
                    },
                    btn: "Guardar",
                }
            },
            image: {
                title: "Avatar | Imagen de portada",
                avatar: {
                    title: "Cambiar avatar",
                    btn: "Subir avatar",
                },
                cover: {
                    title: "Cambiar imagen de portada",
                    btn: "Subir imagen de portada",
                },
            },
            changePass: {
                title: "Cambiar contraseña",
                oldPass: "Contraseña antigua",
                newPass: "Nueva contraseña",
                confirmPass: "Confirmar contraseña",
                btn: "Guardar",
            },
        },

        table: {
            search: {
                placeholder: "Introduzca un nombre de usuario",
                btnSearch: "Buscar en",
                btnReset: "Restablecer",
                filter: "Filtro",
                close: "Cerrar",
            },
        },
    },

    girlsList: {
        card: {
            profile: "Perfil",
            newChat: "Nuevo chat",
            verification: "Verificado",
            desc: "Descripción:",
            age: "Edad:",
        },
        filter: {
            title: "Filters",
            input: "Buscar por nombre",
            age: "Edades",
            country: {
                label: "País",
                placeholder: "Seleccione un país",
            },
            verified: "Perfiles verificados",
        },
        tinder: {
            age: 'Edad:',
            left: "¡Gira a la izquierda!",
            right: "¡Gira a la derecha!",
        },
        empty: "No se han encontrado chicas",
        error: "Error al crear una sala de chat.",
        errorExisting: "Ya existe un chat con la chica en cuestión.",
        success: "¡El chat se ha creado correctamente!",
    },

    footer: {
        desc: "Crea un perfil y descubre el mundo de las citas con chicas interesantes y activas que esperan tu mensaje.",
        category: {
            title: "Categoría",
            item1: "Inicio",
            item2: "Blog",
            item3: "Quiénes somos",
            item4: "Contacto",
        },

        account: {
            title: "Cuenta",
            item1: "Iniciar sesión",
            item2: "Foros",
            item3: "Chats",
            item4: "Favoritos",
        },

        contact: {
            title: "Contacto",
            social: "sociales -",
        },
    },

    notFound: {
        title: "Página no encontrada",
        btn: "Ir al inicio",
    },

    modal: {
        images: {
            titleAvatar: "Subir avatar",
            titleCover: "Subir imagen de portada",
            btn: "Haga clic para cargar",
            success: "archivo cargado correctamente",
            error: "Error al cargar el archivo.",
            errorFormat: "Sólo puede cargar archivos JPG/PNG.",
        },
        media: {
            title: "Cargar imágenes multimedia",
            btn: "Cargar",
        },
        verification: {
            title: "Verificación del usuario",
            camera: {
                open: "Cámara abierta",
                close: "Cerrar Cámara",
                take: "Tomar selfie",
            },
            selfies: {
                title: "Tus selfies:",
                delete: "Borrar",
            },
            info: {
                desc1: "Tendrá que hacerse selfies con su pasaporte para verificar su identidad. Siga estas sencillas instrucciones para que la foto sea aceptada:",
                desc2: "Una vez hecha la foto, asegúrate de que la imagen cumple los requisitos y súbela para su posterior verificación.",
                list: {
                    item1: "Tenga el pasaporte en la mano",
                    item2: "Asegúrese de que la información sea visible",
                    item3: "La foto debe ser clara y nítida, sin desenfoques.",
                },
            },
            permission: {
                undetermined: "Por favor, autoriza el acceso a tu cámara para utilizar esta aplicación.",
                denied: "El acceso a la cámara está denegado. Por favor, conceda permiso para acceder a su cámara.",
                btn: "Permitir cámara",
            },
            btn: "Enviar",
        },
        info: {
            title: "Instrucciones",
            welcome: "Bienvenido,",
            desc1: "Para seguir utilizando todas las funciones de nuestro servicio, siga unos pasos importantes:",
            step1: "Conecte su monedero",
            step2: "Rellenar el perfil",
            step3: "Verifique",
            desc2: "Una vez completados todos los pasos, podrá utilizar plenamente nuestro servicio.",
            btn: "Iniciar los pasos",
        }
    },

    wallet: {
        connection: "Conexión...",
        btnMeta: "Conectar Metamask",
        btnTron: "Conectar Tron",
        btnTronLink: "Conectar TronLink",
        wallet: "Cartera conectada",
        disconnect: "Desactivar Cartera",
        errorMeta: "Instale Metamask para utilizar esta aplicación.",
        errorMob: 'Para los dispositivos móviles se necesita un navegador de dApp especial.',
    }
};
