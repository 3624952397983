import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import Auth from "../Auth";

const CTA = () => {
  const { t } = useTranslation();
  const basePath = "home.cta.";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="cta">
      <section>
        <Row>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className="item-box v1">
              <div className="inner-item-box">
                <div className="inner-image">
                  <img src={require("../../assets/img/cta/img-1.png")} alt="" />
                </div>
                <div className="inner-content">
                  <h4>{t(`${basePath}item1.title`)}</h4>
                  <p>{t(`${basePath}item1.desc`)}</p>
                  <Button type="primary" onClick={handleShow}>
                    {t(`${basePath}btn`)}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <div className="item-box v2">
              <div className="inner-item-box">
                <div className="inner-image">
                  <img src={require("../../assets/img/cta/img-2.png")} alt="" />
                </div>
                <div className="inner-content">
                  <h4>{t(`${basePath}item2.title`)}</h4>
                  <p>{t(`${basePath}item2.desc`)}</p>
                  <Button type="primary" onClick={handleShow}>
                    {t(`${basePath}btn`)}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* Auth Form Modal */}
      <Auth show={show} handleClose={handleClose} />
    </div>
  );
};

export default CTA;
