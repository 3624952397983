import React, { useContext, useState } from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import Auth from "../Auth";

const Banner = () => {
  const { t } = useTranslation();
  const basePath = "home.banner.";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="banner">
      <section>
        <Row className="content">
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <img
              src={require("../../assets/img/banner/banner-img.png")}
              alt=""
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <h1>{t(`${basePath}title`)}</h1>
            <p>{t(`${basePath}desc`)}</p>
              <Button onClick={handleShow}>{t(`${basePath}btn`)}</Button>
          </Col>
        </Row>
      </section>

      {/* Auth Form Modal */}
      <Auth show={show} handleClose={handleClose} />
    </div>
  );
};

export default Banner;
