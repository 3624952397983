import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover, Tooltip } from "antd";
import { UserTypeContext } from "../../../contexts/UserTypeContext";
import {
  IconBadgeCheck,
  IconChat,
  IconInfoCircle,
  IconPerson,
} from "../../../utils/Icons";
import { getCurrentAge } from "../../../utils/getCurrentAge";

const CardUi = ({
  email,
  name,
  age,
  city,
  img,
  id,
  description,
  verification,
  // handleChatCreation = Function.prototype,
  // handleProfileCreation = Function.prototype,
}) => {
  const { t } = useTranslation();
  const basePath = "girlsList.card.";

  const { token } = useContext(UserTypeContext);

  // const handleCreateChat = () => {
  //   handleChatCreation({ id, name });
  // };

  // const handleOpenProfile = () => {
  //   handleProfileCreation({ id, name });
  // };

  return (
    <div className="card-item">
      <div className="image">
        <img src={img} alt={name} />
        {token && (
          <div className="actions">
            <Tooltip title={t(`${basePath}profile`)}>
              <Button onClick={null}>
                <IconPerson />
              </Button>
            </Tooltip>
            {/* <Tooltip title={t(`${basePath}newChat`)}>
              <Button onClick={null}>
                <IconChat />
              </Button>
            </Tooltip> */}
          </div>
        )}
      </div>
      <div className="content">
        <Button onClick={null}>
          <span>
            {name}
            {verification===3 && (
              <Tooltip title={t(`${basePath}verification`)}>
                <IconBadgeCheck
                  width="22"
                  height="22"
                  fill="#1DA1F2"
                  className="verified"
                />
              </Tooltip>
            )}
          </span>
          {description && (
            <Popover content={description} title={t(`${basePath}desc`)}>
              <IconInfoCircle
                width="22"
                height="22"
                fill="var(--color-main)"
                className="info"
              />
            </Popover>
          )}
        </Button>
        <div className="info">
          <span>
            {t(`${basePath}age`)} {getCurrentAge(age)}
          </span>
          <span>{city}</span>
        </div>
      </div>
    </div>
  );
};

export default CardUi;
