import React from "react";

const IconPersonBox = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M4 3C3.448 3 3 3.448 3 4V9C3 9.552 3.448 10 4 10C4.552 10 5 9.552 5 9V5H9C9.552 5 10 4.552 10 4C10 3.448 9.552 3 9 3H4ZM15 3C14.448 3 14 3.448 14 4C14 4.552 14.448 5 15 5H19V9C19 9.552 19.448 10 20 10C20.552 10 21 9.552 21 9V4C21 3.448 20.552 3 20 3H15ZM4 14C3.448 14 3 14.448 3 15V20C3 20.552 3.448 21 4 21H9C9.552 21 10 20.552 10 20C10 19.448 9.552 19 9 19H5V15C5 14.448 4.552 14 4 14ZM20 14C19.448 14 19 14.448 19 15V19H15C14.448 19 14 19.448 14 20C14 20.552 14.448 21 15 21H20C20.552 21 21 20.552 21 20V15C21 14.448 20.552 14 20 14Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12.0112 6C10.3555 6 9.01345 7.343 9.01345 9C9.01345 10.657 10.3555 12 12.0112 12C13.667 12 15.009 10.657 15.009 9C15.009 7.343 13.667 6 12.0112 6ZM11.012 13C9.28525 13 7.54055 14.118 7.04592 15.688C6.84307 16.332 7.33869 17 8.01419 17C9.02844 17 15.5026 17 16.0083 17C16.6928 17 17.1654 16.336 16.9456 15.688C16.4249 14.155 14.6972 13 13.0105 13H11.012Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPersonBox;
