import React from "react";

const IconCheck2 = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        opacity="0.977974"
        d="M7 3C4.791 3 3 4.791 3 6.99999V17C3 19.209 4.791 21 7 21H17C19.209 21 21 19.209 21 17V13C21 12.448 20.552 12 20 12C19.448 12 19 12.448 19 13V17C19 18.105 18.105 19 17 19H7C5.895 19 5 18.105 5 17V6.99999C5 5.895 5.895 5 7 5H15C15.552 5 16 4.552 16 4C16 3.448 15.552 3 15 3H7Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        opacity="0.977974"
        d="M20.9901 3.99841C20.7345 3.99841 20.4669 4.08842 20.2722 4.27942L11.5668 12.8424C11.3102 13.0954 11.0516 13.0774 10.8499 12.7794L9.85143 11.3104C9.54591 10.8594 8.90492 10.7284 8.44763 11.0294C7.98934 11.3304 7.86055 11.9534 8.16607 12.4044L9.16451 13.8734C10.0641 15.2024 11.8244 15.3784 12.9716 14.2484L21.708 5.65442C22.0973 5.27142 22.0973 4.66342 21.708 4.27942C21.5133 4.08742 21.2457 3.99841 20.9901 3.99841Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCheck2;
