import { Col, Row } from "antd";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation();
  const basePath = "home.testimonials.";

  const settings = {
    dots: false,
    arrows: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const sliderItem = [
    {
      name: "Roger Riggs",
      review:
        "I was skeptical, but the site turned out to be really cool. Already met one girl, we are communicating in real life. Thank you!",
      img: require("../../assets/img/reviews/item-5.png"),
    },
    {
      name: "Bryan Garner",
      review:
        "I was pleasantly surprised! The girls here are really interesting and open for communication. Finding a conversation partner was easy, and I have already met a cool girl.",
      img: require("../../assets/img/reviews/item-4.png"),
    },
    {
      name: "Archie Nolan",
      review:
        "¡Gran sitio de citas! Me gusta que todos los perfiles son reales. He encontrado chicas interesantes aquí y la comunicación es muy fácil.",
      img: require("../../assets/img/reviews/item-3.png"),
    },
    {
      name: "Sufyan O'Ryan",
      review:
        "La plataforma es cómoda y fácil. Conocí a varias chicas interesantes en un par de días. ¡La recomiendo a los que buscan comunicación!",
      img: require("../../assets/img/reviews/item-6.png"),
    },
    {
      name: "Aya Aguirre",
      review:
        "I like this site - the guys are interesting, the communication is pleasant. I just registered, and the messages started coming almost immediately.",
      img: require("../../assets/img/reviews/item-1.jpg"),
    },
    {
      name: "Isabella Oliver",
      review:
        "Very convenient platform! It's easy to find a conversation partner here, and I like that guys are really interested in chatting.",
      img: require("../../assets/img/reviews/item-2.jpg"),
    },
  ];

  return (
    <div className="testimonials">
      <section>
        <div className="testimonials-inner">
          <div className="elements-img">
            <img
              src={require("../../assets/img/reviews/elements/img-1.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/reviews/elements/img-2.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/reviews/elements/img-3.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/reviews/elements/img-4.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/reviews/elements/img-5.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/reviews/elements/img-6.png")}
              alt=""
            />
          </div>

          <div className="elements-text">
            <h3>{t(`${basePath}title`)}</h3>
          </div>

          <Row justify="center">
            <Col xl={18} lg={20} md={24} sm={24} xs={24}>
              <Slider {...settings}>
                {sliderItem.map((item, i) => (
                  <div className="slid" key={i}>
                    <div className="item">
                      <img src={item.img} alt="" />
                      <div className="content">
                        <h4>{item.name}</h4>
                        <p>{item.review}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
