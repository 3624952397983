const API_URL = 'https://girlluxury.xyz';

const apiRequest = async (endpoint, method = 'POST', token, body = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (token) headers['x-access-token'] = token;

  const response = await fetch(`${API_URL}/${endpoint}`, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

// Authentication functions
const authLoginGirl = (email, password) =>
  apiRequest('auth_login_girl', 'POST', null, { email, password });

const authRegisterGirl = (email, password) =>
  apiRequest('auth_register_girl', 'POST', null, { email, password });

const authLogin = (email, password) =>
  apiRequest('auth_login', 'POST', null, { email, password });

const authRegister = (email, password) =>
  apiRequest('auth_register', 'POST', null, { email, password });

// User Info functions
const getUserInfo = (email, token) =>
  apiRequest('getUserInfo', 'POST', token, { email });

const getUserInfoGirl = (email, token) =>
  apiRequest('getUserInfoGirl', 'POST', token, { email });

const updateUserInfoGirl = (user_info, token) =>
  apiRequest('updateUserInfoGirl', 'POST', token, { user_info });

const updateUserInfo = (user_info, token) =>
  apiRequest('updateUserInfo', 'POST', token, { user_info });

const updatePhoto = (photo, type, id, token) =>
  apiRequest('updatePhotoUser', 'POST', token, { photo, type, id });

const uploadVerifphoto = (user_info, girl, blob, token) =>
  apiRequest('uploadVerifphoto', 'POST', token, { user_info, girl, blob });

// Chat functions
const sendMessage = (chatId, sender_id, sender_name, receiver_id, receiver_name, message, token, photo_title, photo_title_client) =>
  apiRequest('sendMessage', 'POST', token, {
    chatId,
    sender_id,
    sender_name,
    receiver_id,
    receiver_name,
    messageId: new Date().getTime(),
    message,
    photo_title,
    photo_title_client
  });

const createChat = (chatId, sender_id, sender_name, receiver_id, receiver_name, message, girlinfo, userinfo, token) =>
  apiRequest('createChat', 'POST', token, {
    chatId: chatId,
    sender_id: sender_id,
    sender_name: sender_name,
    receiver_id: receiver_id,
    receiver_name: receiver_name,
    messageId: new Date().getTime(),
    message: message,
    girlinfo: girlinfo,
    userinfo: userinfo
  });

const setReadMessage = (id,chatId,girl,token) =>
  apiRequest('setReadMessage', 'POST', token,{id,chatId,girl});

const getGirlsList = (token) =>
  apiRequest('getGirlsList', 'POST', token);

const getGirlListFree = (token) =>
  apiRequest('getGirlListFree', 'POST', token);

const getChatInfo = (chatId, token) =>
  apiRequest('getChatInfo', 'POST', token, { chatId });

// Password change functions
const changePassword = (chatId, token) =>
  apiRequest('changePassword', 'POST', token, { chatId });

const changePasswordGirl = (email, passwordOld, passwordNew, token) =>
  apiRequest('changePasswordGirl', 'POST', token, {
    email,
    passwordOld,
    passwordNew
  });

// Export all functions
export {
  authRegisterGirl,
  authLoginGirl,
  authLogin,
  authRegister,
  getUserInfo,
  sendMessage,
  getChatInfo,
  getGirlsList,
  createChat,
  getUserInfoGirl,
  updateUserInfoGirl,
  updateUserInfo,
  changePassword,
  changePasswordGirl,
  updatePhoto,
  setReadMessage,
  uploadVerifphoto,
  getGirlListFree
};
