import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { Button, Image, Modal, Tooltip } from "antd";
import localforage from "localforage";
import { useTranslation } from "react-i18next";
import PermissionScreen from "../../utils/PermissionScreen";
import { UserTypeContext } from "../../contexts/UserTypeContext";

import {
  IconCamera,
  IconPersonBox,
  IconRecord,
  IconTrash,
} from "../../utils/Icons";
import axios from "axios";

const VerificationModal = ({ isModalOpen, handleCancel, fetchUserInfo }) => {
  const { t } = useTranslation();
  const basePath = "modal.verification.";

  const { token, girl } = useContext(UserTypeContext);

  const [, setSelfieImage] = useState(null);
  const [previousSelfies, setPreviousSelfies] = useState([]);
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [stream, setStream] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const videoRef = useRef(null);

  const loadPreviousSelfies = useCallback(async () => {
    const keys = await localforage.keys();
    const selfieFiles = await Promise.all(
      keys
        .filter((key) => key.startsWith("selfie_"))
        .map(async (key) => ({
          key,
          imageURL: await localforage.getItem(key),
        }))
    );
    setPreviousSelfies(selfieFiles);
  }, []);

  useEffect(() => {
    if (cameraPermissionGranted) {
      loadPreviousSelfies();
      if (isCameraOpen) {
        startCamera(selectedCamera?.deviceId);
      }
    }
  }, [
    cameraPermissionGranted,
    selectedCamera,
    isCameraOpen,
    loadPreviousSelfies,
  ]);

  useEffect(() => {
    if (cameraPermissionGranted) {
      getAvailableCameras();
    }
  }, [cameraPermissionGranted]);

  const handlePermissionGranted = () => setCameraPermissionGranted(true);

  const startCamera = async (deviceId) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: deviceId ? { exact: deviceId } : undefined },
      });
      setStream(stream);
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const closeCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setStream(null);
      setIsCameraOpen(false);
    }
  };

  const toggleCamera = () => {
    isCameraOpen ? closeCamera() : setIsCameraOpen(true);
  };

  const getAvailableCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setSelectedCamera(videoDevices[0]);
    } catch (error) {
      console.error("Error enumerating devices:", error);
    }
  };

  const takeSelfie = () => {
    if (!isCameraOpen) return;

    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(async (blob) => {
      const urll = girl
        ? "https://girlluxury.xyz/uploadVerifphoto"
        : "https://girlluxury.xyz/uploadVerifphoto";

      const formData = new FormData();
      formData.append("photo", blob, "photo.png");
      const res = axios.post(urll, formData, {
        headers: {
          "x-access-token": token,
          type: girl ? "true" : "false",
          "Content-Type": "multipart/form-data",
        },
      });
      const promise = new Promise((resolve, reject) => {
        resolve(res);
      });

      promise.then(async (imageURL) => {
        setSelfieImage(imageURL.data.url);
      });
      const key = `selfie_${Date.now()}`;
      const imageURL = URL.createObjectURL(blob);
      await localforage.setItem(key, imageURL);
      loadPreviousSelfies();
    }, "image/png");
  };

  const disabledTeg = !isCameraOpen || previousSelfies.length === 1;

  const deleteSelfie = async (key) => {
    await localforage.removeItem(key);
    loadPreviousSelfies();
  };
  const send = async () => {
    fetchUserInfo();
    handleCancel();
  };

  return (
    <Modal
      title={t(`${basePath}title`)}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      className="modal-verify"
    >
      {!cameraPermissionGranted ? (
        <PermissionScreen onPermissionGranted={handlePermissionGranted} />
      ) : (
        <div>
          <div className="selfie-container">
            <div className="buttons">
              <Button onClick={toggleCamera}>
                <IconCamera
                  width="20px"
                  height="20px"
                  fill="var(--color-main)"
                />
                {isCameraOpen
                  ? t(`${basePath}camera.close`)
                  : t(`${basePath}camera.open`)}
              </Button>
            </div>
          </div>

          {isCameraOpen ? (
            <div className="video-container">
              <video ref={videoRef} id="video-preview" autoPlay />
              <Button onClick={takeSelfie} disabled={disabledTeg}>
                {t(`${basePath}camera.take`)}
              </Button>
            </div>
          ) : (
            <img
              src={require("../../assets/img/placeholde-selfi.png")}
              className="mb-30"
              alt=""
            />
          )}

          {previousSelfies.length > 0 && (
            <div className="selfie-items">
              <h4>
                <IconPersonBox
                  width="26"
                  height="26"
                  fill="var(--color-main)"
                />
                {t(`${basePath}selfies.title`)}
              </h4>
              <div className="selfie-previous">
                <Image.PreviewGroup>
                  {previousSelfies.map((selfie, index) => (
                    <div className="selfie-wrapper" key={index}>
                      <Image
                        width={150}
                        height={150}
                        src={selfie.imageURL}
                        alt={`selfi-${index}`}
                      />
                      <Tooltip title={t(`${basePath}selfies.delete`)}>
                        <Button onClick={() => deleteSelfie(selfie.key)}>
                          <IconTrash
                            width="22"
                            height="22"
                            fill="var(--color-red)"
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  ))}
                </Image.PreviewGroup>
              </div>
            </div>
          )}

          <div className="info">
            <p>{t(`${basePath}info.desc1`)}</p>
            <ul>
              <li>
                <IconRecord width="18" height="18" fill="var(--color-main)" />
                {t(`${basePath}info.list.item1`)}
              </li>
              <li>
                <IconRecord width="18" height="18" fill="var(--color-main)" />
                {t(`${basePath}info.list.item2`)}
              </li>
              <li>
                <IconRecord width="18" height="18" fill="var(--color-main)" />
                {t(`${basePath}info.list.item3`)}
              </li>
            </ul>
            <p>{t(`${basePath}info.desc2`)}</p>
          </div>
          <div className="flex justify-center mt-20">
            <Button onClick={send}>{t(`${basePath}btn`)}</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default VerificationModal;
