import React, { useContext, useEffect, useState } from "react";
import UserLayout from "../../components/UserLayout";
import { Col, Empty, Image, Row } from "antd";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import { useNavigate } from "react-router-dom";
import { getUserInfoGirl } from "../../utils/Api";
import { IconBadgeCheck, IconError } from "../../utils/Icons";

const PreviewUser = () => {
  const { token, user_info, emailGirl } = useContext(UserTypeContext);

  const [userInfo, setUserInfoState] = useState({});

  const navigate = useNavigate();

  const getUser = async () => {
    await getUserInfoGirl(emailGirl, token).then(setUserInfoState);
    // setUserInfoState(res);
    // console.log(res);
    
  };

  useEffect(() => {
    if(emailGirl)getUser();
    if (!emailGirl) navigate("/user");
    if (!token) navigate("/");
    if (token && (!user_info.profile || !user_info.photo_title || !user_info.photo_cover)) {
      navigate("/settings");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserLayout userInfo={userInfo} className={"page profile"} type={"view"}>
      <Row>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <h4 className="title-block">About</h4>
          <div className="about">
            <div className="item">
              <span>Name</span>
              {userInfo?.name || "---"}
            </div>
            <div className="item">
              <span>Birthdate</span>
              {userInfo?.date_bith || "---"}
            </div>
            <div className="item">
              <span>Phone No</span>
              {userInfo?.phone || "---"}
            </div>
            <div className="item">
              <span>City</span>
              {userInfo?.location?.country || "---"}
            </div>
            
            {/* <div className="item">
              <span>Emails</span>
              {userInfoPreview.email || "---"}
            </div> */}
          </div>
        </Col>

        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <h4 className="title-block">Media</h4>
          <div className="media-view">
            {userInfo?.photos && userInfo.photos.length > 0 ? (
              <Image.PreviewGroup>
                {userInfo.photos.map((photo, index) => (
                  <div className="media-item" key={index}>
                    <Image
                      width={200}
                      height={200}
                      src={photo}
                      alt={`media-${index}`}
                    />
                  </div>
                ))}
              </Image.PreviewGroup>
            ) : (
              <Empty description={"No pictures"} />
            )}
          </div>
        </Col>
      </Row>
    </UserLayout>
  );
};

export default PreviewUser;
