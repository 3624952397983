import React from "react";

const IconLighting = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M8.4688 13.3124C5.8545 14.0269 4 16.3001 4 18.9999V20.9999C4 21.5522 4.4477 21.9999 5 21.9999H19C19.5523 21.9999 20 21.5522 20 20.9999V18.9999C20 16.3001 18.1455 14.0269 15.5312 13.3124C15.3071 13.2511 15.0869 13.2794 14.875 13.3749C13.9595 13.7875 12.9849 13.9999 12 13.9999C11.0151 13.9999 10.0405 13.7875 9.125 13.3749C8.9131 13.2794 8.6929 13.2511 8.4688 13.3124ZM8.875 15.3124C9.8848 15.6943 10.9197 15.9999 12 15.9999C13.0803 15.9999 14.1152 15.6943 15.125 15.3124C16.8284 15.8284 18 17.2663 18 18.9999V19.9999H6V18.9999C6 17.2663 7.1716 15.8284 8.875 15.3124Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        d="M12 2C9.2386 2 7 4.2386 7 7C7 9.7614 9.2386 12 12 12C14.7614 12 17 9.7614 17 7C17 4.2386 14.7614 2 12 2ZM12 4C13.6569 4 15 5.3431 15 7C15 8.6569 13.6569 10 12 10C10.3431 10 9 8.6569 9 7C9 5.3431 10.3431 4 12 4Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconLighting;
