import React from "react";

const IconTrash = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6V18C7 19.1046 7.89543 20 9 20H15C16.1046 20 17 19.1046 17 18V6H19V18C19 20.2091 17.2091 22 15 22H9C6.79086 22 5 20.2091 5 18V6H7Z"
        fill={fill}
        fill-opacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9978 2C10.8928 2 9.9978 2.89239 9.9978 3.99417H4.9978C4.4458 3.99417 3.9978 4.44086 3.9978 4.99125C3.9978 5.54164 4.4458 5.98833 4.9978 5.98833H18.9978C19.5498 5.98833 19.9978 5.54164 19.9978 4.99125C19.9978 4.44086 19.5498 3.99417 18.9978 3.99417H13.9978C13.9978 2.89239 13.1028 2 11.9978 2ZM10 8.02914C10.5523 8.02914 11 8.47555 11 9.02622V17.0029C11 17.5536 10.5523 18 10 18C9.44772 18 9 17.5536 9 17.0029V9.02622C9 8.47555 9.44772 8.02914 10 8.02914ZM15 9.02622C15 8.47555 14.5523 8.02914 14 8.02914C13.4477 8.02914 13 8.47555 13 9.02622V17.0029C13 17.5536 13.4477 18 14 18C14.5523 18 15 17.5536 15 17.0029V9.02622Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconTrash;
