import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Upload, Button, Avatar } from "antd";
import useMessage from "../../hooks/useMessage";
import axios from "axios";
import { UserTypeContext } from "../../contexts/UserTypeContext";

const UploadCoverModal = ({
  girl,
  token,
  fetchUserInfo,
  isModalOpen,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const basePath = "modal.images.";

  const { user_info } = useContext(UserTypeContext);

  const [avatarUrl, setAvatarUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const { showErrorMessage } = useMessage();

  const handleUploadChange = (file) => {
    const URL = girl
      ? "https://girlluxury.xyz/updatePhotoGirl"
      : "https://girlluxury.xyz/updatePhotoUser";

    const formData = new FormData();
    formData.append("photo", file);
    const res = axios.post(URL, formData, {
      headers: {
        "x-access-token": token,
        type: "cover",
        "Content-Type": "multipart/form-data",
      },
      body: {
        type: "cover",
      },
    });
    const promise = new Promise((resolve, reject) => {
      resolve(res);
    });

    promise.then((res) => {
      setAvatarUrl(res.data.url);
      fetchUserInfo();
      setLoading(false);
    });
  };

  const beforeUpload = (file) => {
    setLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      showErrorMessage(t(`${basePath}errorFormat`));
      return false;
    }
    handleUploadChange(file);
    return false;
  };

  const placeholderImage =
    user_info.photo_cover === null
      ? require("../../assets/img/placeholde-large.png")
      : user_info.photo_cover;

  return (
    <Modal
      title={t(`${basePath}titleCover`)}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <Avatar
          width={"100%"}
          height={"auto"}
          src={avatarUrl || placeholderImage}
          alt="Cover Image"
          className="cover-image"
        />
      </div>
      <Upload name="cover" showUploadList={false} beforeUpload={beforeUpload}>
        <Button loading={loading}>{t(`${basePath}btn`)}</Button>
      </Upload>
    </Modal>
  );
};

export default UploadCoverModal;
