import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import Auth from "../Auth";

const About = () => {
  const { t } = useTranslation();
  const basePath = "home.about.";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="about">
      <section>
        <Row align={"middle"}>
          <Col xl={10} lg={10} md={24} sm={24} xs={24}>
            <div className="image">
              <img src={require("../../assets/img/about.png")} alt="" />

              <span className="logo">
                <img
                  src={require("../../assets/img/logo.svg").default}
                  alt=""
                />
              </span>
            </div>
          </Col>

          <Col xl={14} lg={14} md={24} sm={24} xs={24}>
            <div className="content">
              <h2>
                <span>{t(`${basePath}subtitle`)}</span>
                {t(`${basePath}title`)}
              </h2>
              <p>{t(`${basePath}desc`)}</p>
              <Row className="mt-40 mb-40">
                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="item">
                    <span>17к+</span> {t(`${basePath}stat.user`)}
                  </div>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="item">
                    <span>176к+</span> {t(`${basePath}stat.chats`)}
                  </div>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <div className="item">
                    <span>12</span> {t(`${basePath}stat.years`)}
                  </div>
                </Col>
              </Row>
              <div className="action">
                <Button type="text" onClick={handleShow}>
                  {t(`${basePath}btn`)}
                </Button>
                <span className="text">
                  <img
                    src={require("../../assets/img/icons/author.png")}
                    alt=""
                  />
                  {t(`${basePath}info`)}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* Auth Form Modal */}
      <Auth show={show} handleClose={handleClose} />
    </div>
  );
};

export default About;
