import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { languages } from "../i18n";
import { Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header } from "antd/es/layout/layout";
import { useLanguage } from "../contexts/LanguageContext";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { IconChevronDown, IconExit, IconPerson } from "../utils/Icons";
import Auth from "./Auth";
// import { WalletConnectUi } from "./ui";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.png"),
  "en-US": require("../assets/img/flags/en.png"),
  "de-DE": require("../assets/img/flags/de.png"),
  "es-ES": require("../assets/img/flags/es.png"),
};

function NavBar({ type }) {
  const { t } = useTranslation();
  const basePath = "navBar.";

  const { token } = useContext(UserTypeContext);
  const { changeLanguage } = useLanguage();

  const [show, setShow] = useState(false);

  const [activeLang, setActiveLang] = useState(
    localStorage.getItem("i18nextLng") || "en-US"
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const exit = useCallback(() => {
    localStorage.removeItem("email");
    localStorage.removeItem("pas");
    localStorage.removeItem("checkMe");
    window.location.reload();
  }, []);

  useEffect(() => {
    const storedLang = localStorage.getItem("i18nextLng");
    if (storedLang) {
      setActiveLang(storedLang);
    }
  }, [activeLang]);

  const languagesArr = useMemo(
    () =>
      languages.map((item, index) => ({
        key: index,
        label: (
          <span className="item-lang">
            <img src={langIcons[item]} alt="" />
            {t(`${basePath}lang.${item}`)}
          </span>
        ),
      })),
    [t, basePath]
  );

  const items = [
    // {
    //   key: "0",
    //   label: <Link to="/profile">{t(`${basePath}menu.profil`)}</Link>,
    //   icon: <IconPerson width="18" height="18" fill="#ababab" />,
    // },
    // {
    //   key: "1",
    //   label: <Link to="/profile">{t(`${basePath}menu.chats`)}</Link>,
    //   icon: <IconPerson width="18" height="18" fill="#ababab" />,
    // },
    // {
    //   key: "2",
    //   label: <Link to="/profile">{t(`${basePath}menu.setting`)}</Link>,
    //   icon: <IconPerson width="18" height="18" fill="#ababab" />,
    // },
    // {
    //   type: "divider",
    // },
    {
      key: "3",
      label: t(`${basePath}menu.exit`),
      icon: <IconExit width="18" height="18" fill="#ababab" />,
    },
  ];

  const onUserNavClick = ({ key }) => {
    if (key === "1") {
      exit();
    }
  };

  const onLangChange = useCallback(
    ({ key }) => {
      const lang = languages[+key];
      localStorage.setItem("i18nextLng", lang);
      setActiveLang(lang);
      changeLanguage(lang);
    },
    [changeLanguage]
  );

  return (
    <>
      <Header className={`nav-bar ${type}`}>
        <Link to="/" className="logo">
          {type !== "large" && (
            <img
              src={require("../assets/img/logo.svg").default}
              alt="Luxurgirl"
            />
          )}
          <span>Luxurgirl®</span>
        </Link>

        <div className="right-side">
          {token && (
            <button type="button" className="dropdown-btn" onClick={exit}>
              <IconExit width="18" height="18" fill="#ababab" />
              {t(`${basePath}menu.exit`)}
            </button>
          )}

          <Dropdown
            menu={{ items: languagesArr, onClick: onLangChange }}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <button type="button" className="dropdown-btn">
              <img src={langIcons[activeLang]} alt="" />
              {t(`${basePath}lang.${activeLang}`)}
              <span className="arrow">
                <IconChevronDown
                  width="14"
                  height="14"
                  fill="var(--color-font)"
                />
              </span>
            </button>
          </Dropdown>

          {!token && (
            <Button type="primary" onClick={handleShow}>
              {t(`${basePath}logIn`)}
            </Button>
          )}
        </div>
      </Header>

      {/* Auth Form Modal */}
      <Auth show={show} handleClose={handleClose} />
    </>
  );
}

export default NavBar;
