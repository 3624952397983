import React, { useState, useEffect } from "react";
import { Modal, Upload, Image } from "antd";
import { useTranslation } from "react-i18next";
import { IconPlusCircle } from "../../utils/Icons";
import axios from "axios";
import useMessage from "antd/es/message/useMessage";

const UploadMediaModal = ({
  setUserInfo,
  isModalOpen,
  handleCancel,
  userInfo,
  token,
  fetchUserInfo,
  girl,
}) => {
  const { t } = useTranslation();
  const basePath = "modal.media.";
  const { showErrorMessage } = useMessage();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userInfo.photos) {
      const initialFileList = userInfo.photos.map((photo, index) => ({
        uid: `${index}`,
        name: `image-${index}`,
        status: "done",
        url: photo,
      }));
      setFileList(initialFileList);
    }
  }, [userInfo.photos]);

  const beforeUpload = async (file) => {
    setLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      showErrorMessage(t(`${basePath}errorFormat`));
      return false;
    }
    handleChange(file);
    return false;
  };

  const handleChange = async (file) => {
    const URL = girl
      ? "https://girlluxury.xyz/updatePhotoGirl"
      : "https://girlluxury.xyz/updatePhotoUser";

    const formData = new FormData();
    formData.append("photo", file);
    const res = axios.post(URL, formData, {
      headers: {
        "x-access-token": token,
        type: "photos",
        "Content-Type": "multipart/form-data",
      },
    });
    const promise = new Promise((resolve, reject) => {
      resolve(res);
    });

    promise.then((res) => {
      setPreviewImage(res.data.url);
      fetchUserInfo();
      setLoading(false);
    });
  };

  const handleRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedPhotos = userInfo.photos.filter((photo) => photo !== file.url);

    setUserInfo((prevInfo) => ({
      ...prevInfo,
      photos: updatedPhotos,
    }));
  };

  const uploadButton = (
    <button type="button">
      <IconPlusCircle width="20" height="20" fill="var(--color-font)" />
      {t(`${basePath}btn`)}
    </button>
  );

  return (
    <Modal
      title={t(`${basePath}title`)}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      className="modal-media"
      loading={loading}
    >
      <Upload
        maxCount={1}
        listType="picture-card"
        fileList={fileList}
        beforeUpload={beforeUpload}
        onRemove={handleRemove}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
          }}
          src={previewImage}
        />
      )}
    </Modal>
  );
};

export default UploadMediaModal;
