import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Routess from "./router/Routes";
import MessageProvider from "./contexts/MessageContext";
import { UserTypeProvider } from "./contexts/UserTypeContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { wagmiConfig } from "./components/ui/elements/wagmiConfig";
import { WagmiProvider } from "wagmi";

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <UserTypeProvider>
            <MessageProvider>
              <WagmiProvider config={wagmiConfig}>
                <Routess />
              </WagmiProvider>
            </MessageProvider>
          </UserTypeProvider>
        </LanguageProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
