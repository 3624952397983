import React from "react";

const IconCalendar = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 4.791 4.7909 3 7 3H17C19.209 3 21 4.791 21 7V17C21 19.209 19.209 21 17 21H7C4.7909 21 3 19.209 3 17V7ZM17 5H7C5.8954 5 5 5.895 5 7V17C5 18.105 5.8954 19 7 19H17C18.105 19 19 18.105 19 17V7C19 5.895 18.105 5 17 5ZM8 6.99998C7.44772 6.99998 7 7.4477 7 7.99998C7 8.55227 7.44772 8.99998 8 8.99998H16C16.5523 8.99998 17 8.55227 17 7.99998C17 7.4477 16.5523 6.99998 16 6.99998H8Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V3C7 2.44772 7.44772 2 8 2ZM13.844 11C13.51 11 13.203 11.062 12.938 11.156C12.672 11.251 12.468 11.366 12.281 11.531C12.094 11.697 11.943 11.903 11.844 12.125C11.745 12.347 11.688 12.586 11.688 12.844C11.687 13.085 11.727 13.289 11.812 13.5C11.898 13.711 12.037 13.906 12.188 14.062C12.338 14.219 12.509 14.347 12.719 14.438C12.928 14.528 13.159 14.562 13.406 14.562C13.4585 14.5628 13.5192 14.5512 13.5849 14.5386L13.5849 14.5386L13.625 14.531C13.706 14.516 13.811 14.479 13.938 14.406H13.969L12.5 16H14.031L15.219 14.625C15.485 14.315 15.684 14.008 15.812 13.688C15.941 13.367 16 13.057 16 12.781C16 12.536 15.974 12.311 15.875 12.094C15.776 11.877 15.624 11.695 15.438 11.531C15.251 11.368 15.016 11.251 14.75 11.156C14.484 11.062 14.182 11 13.844 11ZM8 11.125V12.219H8.75V16H10.062V11.125H8ZM13.844 12C13.961 12 14.086 12.019 14.188 12.062C14.2827 12.1023 14.3519 12.1682 14.4237 12.2364L14.438 12.25C14.514 12.323 14.58 12.403 14.625 12.5C14.67 12.597 14.688 12.701 14.688 12.812C14.687 12.924 14.67 13.028 14.625 13.125C14.58 13.222 14.514 13.302 14.438 13.375L14.4271 13.3853C14.3541 13.4547 14.2842 13.521 14.188 13.562C14.086 13.605 13.961 13.625 13.844 13.625C13.727 13.625 13.633 13.606 13.531 13.562C13.43 13.519 13.327 13.448 13.25 13.375C13.173 13.302 13.108 13.222 13.062 13.125C13.017 13.028 13 12.924 13 12.812C13 12.701 13.017 12.597 13.062 12.5C13.108 12.403 13.173 12.323 13.25 12.25C13.327 12.177 13.43 12.106 13.531 12.062C13.633 12.019 13.727 12 13.844 12ZM17 3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4C15 4.55228 15.4477 5 16 5C16.5523 5 17 4.55228 17 4V3Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCalendar;
