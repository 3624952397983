export const TRANSLATIONS_DE = {
    navBar: {
        lang: {
            'en-US': "Englisch",
            'de-DE': "Deutsch",
            'es-ES': "Spanisch",
            'ru-RU': "Russisch"
        },
        profile: "Profil",
        exit: 'Abmelden',
        logIn: "Einloggen",
        menu: {
            profil: "Profil",
            chats: "Chats",
            setting: "Einstellungen",
            exit: 'Abmelden',
        }
    },

    sideBar: {
        menu: {
            profil: "Profil",
            chats: "Chats",
            friend: "Freunde",
            media: "Medien",
            setting: "Einstellungen",
            girls: "Mädchen",
            exit: 'Abmelden',
        },
    },

    auth: {
        title: "Autorisierung",
        loginTab: 'Anmeldung',
        signUpTab: 'Registrierung',
        form: {
            email: {
                label: "Anmeldung",
                placeholder: "Ihre Anmeldung eingeben",
            },
            pass: {
                label: "Passwort",
                placeholder: "Geben Sie Ihr Passwort ein",
            },
            passConfirm: {
                label: "Bestätigen Sie das Passwort",
                placeholder: "Geben Sie Ihr Passwort ein",
            },
            type: {
                label: "Konto-Typ",
                placeholder: "Wählen Sie einen Kontotyp",
                item: {
                    user: 'Benutzer',
                    girl: 'Teilnehmer',
                }
            },
            remember: "Erinnere mich",
            btnLog: 'Einloggen',
            btnSig: 'Registrieren'
        },
        success: {
            registrationSuccess: "Sie haben sich erfolgreich registriert! Bitte melden Sie sich bei Ihrem Konto an.",
        },
        errors: {
            userNotFound: "Benutzer nicht gefunden!",
            serverError: "Server-Fehler",
            missingFields: 'Bitte füllen Sie alle Felder aus!',
            passwordMismatch: "Passwörter stimmen nicht überein",
            emailInUse: "Fehlgeschlagen! E-Mail ist bereits in Gebrauch!",
        },
    },

    home: {
        banner: {
            title: "Lerne charmante Mädchen kennen und beginne sofort mit ihnen zu schlafen!",
            desc: 'Erstellen Sie ein Profil und entdecken Sie die Welt des Datings mit interessanten und aktiven Mädchen, die auf Ihre Nachricht warten.',
            btn: "Fangen Sie an!",
        },

        cta: {
            item1: {
                title: "Treffen Sie die Mädchen!",
                desc: "Erstellen Sie ein Profil und beginnen Sie mit den Mädchen zu chatten, die auf Ihre Nachricht warten.",
            },
            item2: {
                title: "Finden Sie neue Bekanntschaften!",
                desc: "Erstellen Sie ein Profil und beginnen Sie, Nachrichten von Männern zu erhalten.",
            },
            btn: "Jetzt beginnen",
        },

        advantages: {
            item1: {
                title: "Benutzerfreundliche Schnittstelle",
                desc: "Dank des intuitiven Designs können Sie Ihr Profil ganz einfach anpassen und neue Bekanntschaften finden.",
            },
            item2: {
                title: "Echte Mädchen",
                desc: "Alle Profile sind verifiziert, so dass Sie nur mit echten Menschen chatten.",
            },
            item3: {
                title: "Schnellstart",
                desc: "Es dauert nur ein paar Minuten, sich anzumelden, und schon können Sie mit dem Chatten beginnen.",
            },
            item4: {
                title: "Datenschutz",
                desc: "Wir kümmern uns um Ihre Privatsphäre, indem wir Ihre persönlichen Daten sicher aufbewahren.",
            },
        },

        about: {
            title: "Über das Unternehmen",
            subtitle: "Finden Sie Ihren Lebenspartner",
            desc: "Wir sind eine moderne Plattform, die Menschen für Kommunikation, Freundschaft und romantisches Dating zusammenbringt. Unser Ziel ist es, den Prozess der Kommunikation so bequem und lustig wie möglich zu machen. Alle Mädchen, die Profile erstellen, wurden verifiziert, was Ehrlichkeit und Authentizität der Kommunikation garantiert. Wir entwickeln unseren Service ständig weiter und fügen neue Funktionen hinzu, um Ihr Chatterlebnis zu verbessern. Treten Sie uns bei und finden Sie Ihre perfekte Begleitung jetzt!",
            stat: {
                user: "Benutzer",
                chats: "Chats",
                years: "Jahre der Erfahrung",
            },
            btn: "Fangen Sie an!",
            info: "Mehr als 10 Millionen vertrauenswürdige Kunden weltweit",
        },

        testimonials: {
            title: "Testimonials",
        },

        keep: {
            title: "Bleiben Sie in Kontakt mit der Gemeinschaft",
            desc: "Melde dich jetzt bei Luxurgirl an und finde deinen Lebenspartner!",
            btn: "Fangen Sie an!",
        },
    },

    user: {
        layout: {
            name: 'Ihr Name',
            media: "Medien",
            description: {
                title: "etwas über mich...",
                error: "Sie haben Ihre Biographie erfolgreich aktualisiert!",
                success: "Biografie konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.",
            },
            btnVerified: "Profil überprüfen",
            verified: "Geprüft",
            proccesing: "Im Gange",
        },

        profile: {
            about: {
                title: "Über",
                name: "Name",
                date: "Geburtsdatum",
                phone: "Telefon Nr.",
                city: "Stadt",
                email: "Email",
            },
            chats: "Letzte Chats",
        },

        chat: {
            title: "Chats",
            recent: "Neueste",
            placeholder: {
                title: "Willkommen,",
                desc: "Bitte wählen Sie einen Chat aus, um eine Nachricht zu senden.",
            },
            profile: "Profil",
            input: "Nachricht eingeben",
            btn: "Senden Sie",
            btnFile: "Datei senden"
        },

        friends: {
            title: "Freunde",
            table: {
                user: "Benutzer",
                age: "Alter",
                country: "Land",
                status: {
                    title: "Status",
                    online: "Online",
                    offline: "Offline",
                },
                actions: {
                    title: "Aktionen",
                    profile: "Profil",
                }
            },
        },

        media: {
            title: "Medien",
            btnUpload: "Fotos hochladen",
            btnDelete: "Foto löschen",
            empty: 'Keine Bilder',
        },

        settings: {
            alert: "Bitte füllen Sie Ihr Profil aus",
            wallet: "Brieftasche verbinden",
            personal: {
                title: "Persönliche Informationen",
                verif: {
                    btn: "Profil überprüfen",
                    verified: "Geprüft",
                    proccesing: "Im Gange",
                },
                alertVerif: "Verifizieren",
                alertWallet: "Verbinden Sie Ihr Portemonnaie",
                form: {
                    name: {
                        label: "Name",
                        placeholder: "Geben Sie Ihren Namen ein",
                        error: "Bitte geben Sie Ihren Namen ein",
                    },
                    email: {
                        label: "Email",
                    },
                    birthday: {
                        label: "Geburtstag",
                        error: "Bitte geben Sie Ihr Geburtsdatum ein",
                    },
                    phone: {
                        label: "Rufnummer",
                        placeholder: "Geben Sie Ihre Rufnummer ein",
                        error: "Bitte geben Sie eine Telefonnummer ein",
                    },
                    location: {
                        country: {
                            title: "Land",
                            placeholder: "Land auswählen",
                        },
                        state: {
                            title: "Staat",
                            placeholder: "Staat auswählen",
                        },
                        city: {
                            title: "Stadt",
                            placeholder: "Stadt auswählen",
                        },
                    },
                    btn: "Speichern Sie",
                }
            },
            image: {
                title: "Avatar | Titelbild",
                avatar: {
                    title: "Avatar ändern",
                    btn: "Avatar hochladen",
                },
                cover: {
                    title: "Titelbild ändern",
                    btn: "Titelbild hochladen",
                },
            },
            changePass: {
                title: "Passwort ändern",
                oldPass: "Altes Passwort",
                newPass: "Neues Passwort",
                confirmPass: "Bestätigen Sie das Passwort",
                btn: "Speichern Sie",
            },
        },

        table: {
            search: {
                placeholder: "Eingabe eines Benutzernamens",
                btnSearch: "Suche",
                btnReset: "Zurücksetzen",
                filter: "Filter",
                close: "Schließen",
            },
        },
    },

    girlsList: {
        card: {
            profile: "Profil",
            newChat: "Neuer Chat",
            verification: "Geprüft",
            desc: "Beschreibung:",
            age: "Alter:",
        },
        filter: {
            title: "Filters",
            input: "Suche nach Namen",
            age: "Altersspanne",
            country: {
                label: "Land",
                placeholder: "Land auswählen",
            },
            verified: "Geprüfte Profile",
        },
        tinder: {
            age: 'Alter:',
            left: "Nach links streichen!",
            right: "Nach rechts streichen!",
        },
        empty: "Keine Mädchen gefunden",
        error: "Fehler beim Erstellen eines Chatraums.",
        errorExisting: "Ein Chatroom mit dem fraglichen Mädchen existiert bereits.",
        success: "Der Chat wurde erfolgreich erstellt!",
    },

    footer: {
        desc: "Erstellen Sie ein Profil und entdecken Sie die Welt des Datings mit interessanten und aktiven Mädchen, die auf Ihre Nachricht warten.",
        category: {
            title: "Kategorie",
            item1: "Startseite",
            item2: "Blog",
            item3: "Über uns",
            item4: "Kontakt",
        },

        account: {
            title: "Konto",
            item1: "Eintragen",
            item2: "Foren",
            item3: "Chats",
            item4: "Favorit",
        },

        contact: {
            title: "Kontakt",
            social: "sozial -",
        },
    },

    notFound: {
        title: "Seite nicht gefunden",
        btn: "Nach Hause gehen",
    },

    modal: {
        images: {
            titleAvatar: "Avatar hochladen",
            titleCover: "Titelbild hochladen",
            btn: "Zum Hochladen klicken",
            success: "datei erfolgreich hochgeladen",
            error: "Dateiupload fehlgeschlagen.",
            errorFormat: "Sie können nur JPG/PNG-Dateien hochladen!",
        },
        media: {
            title: "Hochladen von Medienbildern",
            btn: "Hochladen",
        },
        verification: {
            title: "Überprüfung der Benutzer",
            camera: {
                open: "Offene Kamera",
                close: "Kamera schließen",
                take: "Selfie machen",
            },
            selfies: {
                title: "Deine Selfies:",
                delete: "Löschen",
            },
            info: {
                desc1: "Sie müssen Selfies mit Ihrem Reisepass machen, um Ihre Identität zu überprüfen. Bitte befolgen Sie diese einfachen Anweisungen, damit das Foto akzeptiert wird:",
                desc2: "Vergewissern Sie sich nach der Aufnahme, dass das Bild den Anforderungen entspricht, und laden Sie es zur weiteren Überprüfung hoch.",
                list: {
                    item1: "Halten Sie Ihren Reisepass in der Hand",
                    item2: "Stellen Sie sicher, dass die Informationen sichtbar sind",
                    item3: "Das Foto sollte klar und scharf sein, ohne Unschärfe.",
                },
            },
            permission: {
                undetermined: "Bitte erteilen Sie die Erlaubnis, auf Ihre Kamera zuzugreifen, um diese App zu verwenden.",
                denied: "Der Zugriff auf die Kamera wird verweigert. Bitte erlauben Sie den Zugriff auf Ihre Kamera.",
                btn: "Kamera zulassen",
            },
            btn: "Senden Sie",
        },
        info: {
            title: "Anleitung",
            welcome: "Willkommen,",
            desc1: "Um weiterhin alle Funktionen unseres Dienstes nutzen zu können, müssen Sie einige wichtige Schritte befolgen:",
            step1: "Verbinden Sie Ihr Portemonnaie",
            step2: "Füllen Sie das Profil aus",
            step3: "Überprüfen Sie",
            desc2: "Sobald alle Schritte abgeschlossen sind, können Sie unseren Dienst in vollem Umfang in Anspruch nehmen.",
            btn: "Starten Sie die Schritte",
        }
    },

    wallet: {
        connection: "Verbindung...",
        btnMeta: "Metamaske verbinden",
        btnTron: "Tron verbinden",
        btnTronLink: "TronLink verbinden",
        wallet: "Brieftasche verbunden",
        disconnect: "Brieftasche deaktivieren",
        errorMeta: "Bitte installieren Sie Metamask, um diese Anwendung zu nutzen.",
        errorMob: 'Für mobile Geräte ist ein spezieller dApp-Browser erforderlich.',
    }
};
