import { Button, Modal } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconPencil, IconPersonBox, IconWallet } from "../../utils/Icons";
import { UserTypeContext } from "../../contexts/UserTypeContext";

const InfoModal = ({ isModalOpen, handleCancel }) => {
  const { t } = useTranslation();
  const basePath = "modal.info.";

  const { user_info } = useContext(UserTypeContext);

  return (
    <Modal
      title={t(`${basePath}title`)}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      className="modal-welcome"
    >
      <h2>
        {t(`${basePath}welcome`)} {user_info.name}!
      </h2>
      <p>{t(`${basePath}desc1`)}</p>
      <ul>
        <li>
          <IconWallet width="22" height="22" fill="var(--color-main)" />
          {t(`${basePath}step1`)}
        </li>
        <li>
          <IconPencil width="22" height="22" fill="var(--color-main)" />
          {t(`${basePath}step2`)}
        </li>
        <li>
          <IconPersonBox width="22" height="22" fill="var(--color-main)" />
          {t(`${basePath}step3`)}
        </li>
      </ul>
      <p>{t(`${basePath}desc2`)}</p>
      <Button onClick={handleCancel}>{t(`${basePath}btn`)}</Button>
    </Modal>
  );
};

export default InfoModal;
