import React from "react";

const IconGeo = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M11.9978 2C9.2364 2 6.9978 4.239 6.9978 7C6.9978 7.936 7.4399 9.08699 8.2478 10.469C8.5169 10.929 8.8513 11.383 9.1853 11.875C9.6357 12.539 10.1108 13.223 10.5918 13.844C10.7018 13.985 10.8498 14.182 10.9978 14.375V17C10.9978 17.552 11.4458 18 11.9978 18C12.5498 18 12.9978 17.552 12.9978 17V14.375C13.1458 14.182 13.2938 13.985 13.4038 13.844C13.8848 13.224 14.3598 12.539 14.8098 11.875C15.1438 11.383 15.4788 10.929 15.7478 10.469C16.5558 9.08699 16.9978 7.936 16.9978 7C16.9978 4.239 14.7588 2 11.9978 2ZM11.9978 4C13.6548 4 14.9978 5.343 14.9978 7C14.9978 7.476 14.6368 8.34499 13.9978 9.43799C13.7528 9.85599 13.4958 10.324 13.1858 10.781C12.8118 11.332 12.3998 11.85 11.9978 12.375C11.5958 11.85 11.1848 11.332 10.8098 10.781C10.4998 10.324 10.2428 9.85599 9.9978 9.43799C9.359 8.34499 8.9978 7.476 8.9978 7C8.9978 5.343 10.3408 4 11.9978 4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8ZM4 17.5C4 15.819 5.4405 14.463 7.6562 13.688C8.1775 13.505 8.7551 13.791 8.9375 14.312C9.1199 14.834 8.8338 15.38 8.3125 15.562C6.808 16.089 6 16.868 6 17.5C6 18.692 8.6154 20 12 20C15.385 20 18 18.692 18 17.5C18 16.866 17.167 16.089 15.656 15.562C15.135 15.381 14.881 14.834 15.062 14.312C15.244 13.791 15.791 13.506 16.312 13.688C18.535 14.462 20 15.815 20 17.5C20 20.174 16.347 22 12 22C7.6526 22 4 20.174 4 17.5Z"
        fill={fill}
        fillOpacity={0.4}
      />
    </svg>
  );
};

export default IconGeo;
