import { createContext, useReducer } from "react";
import { Reducer } from "../reducer/Reducer";

export const UserTypeContext = createContext();

const initialState = {
  token: null,
  girl: false,
  user_info: {},
  girl_list: [],
  email: null,
  emailGirl:null,
  loading:null,
  hideSlide:true,
  isWalletConnect:false,
  isModalInfo:true
};



export const UserTypeProvider = ({ children }) => {
  const [value, dispatch] = useReducer(Reducer, initialState);

  value.setGirl = () => {
    dispatch({ type: "setGirl" });
  };
  value.setHideSlide = () => {
    dispatch({ type: "setHideSlide" });
  };
  value.setIsWalletConnect = (data) => {
    dispatch({ type: "setIsWalletConnect",payload:data });
  };
  value.setModalInfo = (data) => {
    dispatch({ type: "setModalInfo",payload:data });
  };
  value.setUserInfo = (data) => {
    dispatch({ type: "setUserInfo", payload: data });
  };
  value.setGirlList = (data) => {
    dispatch({ type: "setGirlList", payload: data });
  };
  value.setToken = (data) => {
    dispatch({ type: "setToken", payload: data });
  };
  value.setEmail = (data) => {
    dispatch({ type: "setEmail", payload: data });
  };
  value.setEmailGirl = (data) => {
    dispatch({ type: "setEmailGirl", payload: data });
  };
  value.setLoading = () => {
    dispatch({ type: "setLoading" });
  };

  return (<UserTypeContext.Provider value={value}>{children}</UserTypeContext.Provider>);
};
