import React from "react";

const IconArrowReturnLeft = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9C21 11.2091 19.2091 13 17 13L6.41409 13C5.41016 12 5.41016 12 5.41016 12C5.41016 12 5.41016 12 6.41428 11H17C18.1046 11 19 10.1046 19 9V4C19 3.44772 19.4477 3 20 3C20.5523 3 21 3.44772 21 4V9Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4141 17.0001L5.41406 12.0001L10.4141 7.00009L8.99985 5.58588L3.29274 11.293C2.90222 11.6835 2.90222 12.3167 3.29274 12.7072L8.99985 18.4143L10.4141 17.0001Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrowReturnLeft;
