import React from "react";

const IconPencil = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 4.79086 4.79086 3 7 3H11C11.5523 3 12 3.44772 12 4C12 4.55228 11.5523 5 11 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z"
        fill={fill}
        fillOpacity={0.4}
      />
      <path
        opacity="0.986784"
        d="M17 2C16.735 2 16.469 2.09401 16.281 2.28101C14.781 3.78101 14.781 3.78101 14.281 4.28101L7.28101 11.281C7.09401 11.469 7 11.735 7 12V16C7 16.552 7.448 17 8 17C8.5 17 11.5 17 12 17C12.265 17 12.531 16.906 12.719 16.719L19.719 9.71899C20.219 9.21899 20.219 9.21899 21.719 7.71899C21.906 7.53099 22 7.265 22 7C22 5.364 21.583 4.19501 20.719 3.31201C19.846 2.42201 18.681 2 17 2ZM17.407 4.01898C18.309 4.07198 18.88 4.27799 19.281 4.68799C19.691 5.10599 19.927 5.73701 19.982 6.62201C19.493 7.11101 19.19 7.40101 19.004 7.58801C18.211 6.79601 17.205 5.79898 16.413 5.00598C16.6 4.81898 16.918 4.50798 17.407 4.01898ZM15.002 6.42603L17.587 9.00403L11.562 15C10.93 15 9.921 15 9 15V12.438L15.002 6.42603Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPencil;
